var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ctask_list && _vm.ctask_list.length
    ? _c("div", { staticClass: "c_multitask_fun_bar_index" }, [
        _c(
          "div",
          { staticClass: "wrapper_group d-flex flex-column ul_sel bbox" },
          [
            _c("div", {
              staticClass: "icon clear cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.clearAll()
                },
              },
            }),
            _vm._l(_vm.ctask_list, function (item, index) {
              return [
                _c("unit-component", {
                  key: index,
                  attrs: { large_task: item },
                  on: { handleDelete: _vm.handleDelete },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.recoverTask(item, index)
                    },
                  },
                }),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }