<!--
 * @Author       : Hugo
 * @Date         : 2020-06-08 11:04:25
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-06-08 12:20:27
 * @Description  : 
 * @FilePath     : \miaohang\src\components\communicationRecord\msgUnit.vue
--> 
<template>
  <div class="communication_record_msg_unit">
    <div class="avatar_group">
      <img src="" alt="" class="avatar">
      <div class="name">赵老师</div>
    </div>
    <div class="container_group">
      <div class="container_box" :class="{template: type == 'template'}">
        <img src="@/assets/images/dialog/template_arrow.png" alt="" class="arrow" v-if="type == 'template'">
        <img src="@/assets/images/dialog/receive_arrow.png" alt="" class="arrow" v-if="type != 'template'">
        <div class="text" v-if="type != 'file'">
          <slot name="text"></slot>
        </div>
        <div class="filename_group" v-if="type == 'file'">
          <div class="filename">
            <slot name="filename"></slot>
          </div>
          <div class="size">11.6M</div>
        </div>
        <img src="@/assets/images/common/file_ppt.png" alt="" class="file" v-if="type == 'file'">
        
      </div>
    </div>
    <div class="fun_group">
      <div class="time">2020-6-8 11:15</div>
      <div class="check cp">查看上下文</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      defult: 'template',
    }
  }
}
</script>
<style lang="scss" scoped>
.communication_record_msg_unit{
  @include flexsb;
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
  .avatar_group{
    flex-shrink: 0;
    @include flex;
    flex-direction: column;
    justify-content: center;
    .avatar{
      @include avatar;
    }
    .name{
      @include single_line_intercept;
      padding-top: 8px;
      text-align: center;
      font-size:12px;
      
      font-weight:400;
      color:rgba(153,153,153,1);
    }
  }
  .fun_group{
    flex-shrink: 0;
    @include flexsb;
    align-items: flex-start;
    flex-direction: column;
    height: 72px;
    .time{
      font-size:12px;
      
      font-weight:400;
      color:rgba(153,153,153,1);
    }
    .check{
      @include transition;
      font-size:12px;
      
      font-weight:400;
      color:rgba(102,102,102,1);
      &:hover{
        color: $main_blue;
      }
    }
  }
  .container_group{
    @include bbox;
    @include flex;
    padding: {
      left:15px;
      right: 18px;
    }
    position: relative;
    width: 100%;
    flex: 1;
    .container_box{
      @include bbox;
      @include flex;
      max-width: 100%;
      padding: 20px;
      position: relative;
      min-height: 72px;
      border-radius:0px 4px 4px 4px;
      background-color: #E5EDFA;
      .arrow{
        position: absolute;
        display: block;
        width:5px;
        height: 7px;
        top:0;
        left: -5px;
      }
      &.template{
        background-color: #E8E8E8;
      }
      .file{
        flex-shrink: 0;
        width: 50px;
        height: 50px;
      }
      .filename_group{
        @include bbox;
        @include flexsb;
        flex-direction: column;
        align-items: flex-start;
        height: 50px;
        padding-right: 40px;
        .filename{
          font-size:14px;
          
          font-weight:400;
          color:rgba(51,51,51,1);
          line-height:20px;
          &:hover{
            text-decoration: underline;
          }
        }
        .size{
          font-size:12px;
          
          font-weight:400;
          color:rgba(51,51,51,1);
          line-height:20px;
        }
      }
    }
    .text{
      @include transition;
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:20px;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
</style>