var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "activation_confirm_page" }, [
    _c("div", { staticClass: "list_group" }, [
      _c("div", { staticClass: "confirm_table_wrapper" }, [
        _c("div", { staticClass: "confirm_table" }, [
          _vm._m(0),
          _vm.acti_confirm_data.batch
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "key_rowspan" }, [_vm._v("会员权益")]),
                _c("div", { staticClass: "val_list" }, [
                  _c("div", { staticClass: "list_unit" }, [
                    _vm._v(
                      "vip会员" +
                        _vm._s(_vm.acti_confirm_data.batch.vipdays) +
                        "天"
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.acti_confirm_data.tasklist
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "key_rowspan" }, [_vm._v("模板")]),
                _c(
                  "div",
                  { staticClass: "val_list" },
                  _vm._l(
                    _vm.acti_confirm_data.tasklist,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "list_unit" },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          _vm.acti_confirm_data.packagelist
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "key_rowspan" }, [_vm._v("教学包")]),
                _c(
                  "div",
                  { staticClass: "val_list" },
                  _vm._l(
                    _vm.acti_confirm_data.packagelist,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "list_unit" },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "fun_group" }, [
      _c(
        "button",
        {
          staticClass: "btn button sure",
          on: {
            click: function ($event) {
              return _vm.doSureConfirm()
            },
          },
        },
        [_vm._v("确认激活")]
      ),
      _c(
        "button",
        {
          staticClass: "btn button cancel",
          on: { click: _vm.closeActiConfirmPage },
        },
        [_vm._v("取消激活")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "key" }, [_vm._v("产品类别")]),
      _c("div", { staticClass: "val" }, [_vm._v("产品详情")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }