<!--
 * @Author       : Hugo
 * @Date         : 2020-06-08 11:05:40
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-06-08 12:36:09
 * @Description  : 沟通记录container
 * @FilePath     : \miaohang\src\components\communicationRecord\container.vue
--> 
<template>
  <div class="communication_record_container">
    <div class="unit">
      <CommunicationRecordMsgUnit type="template">
        <template v-slot:text>
          <span v-html="$tools.highlightKeywords('按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述',196)"></span>
        </template>
        
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="msg">
        <template v-slot:text>
          <span v-html="$tools.highlightKeywords('按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="file">
        <template v-slot:filename>
          <span v-html="$tools.highlightKeywords('ppt1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="template">
        <template v-slot:text>
          <span v-html="$tools.highlightKeywords('按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="msg">
        <template v-slot:text>
          <span v-html="$tools.highlightKeywords('按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="file">
        <template v-slot:filename>
          <span v-html="$tools.highlightKeywords('ppt1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="template">
        <template v-slot:text>
          <span v-html="$tools.highlightKeywords('按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="msg">
        <template v-slot:text>
          <span v-html="$tools.highlightKeywords('按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    <div class="unit">
      <CommunicationRecordMsgUnit type="file">
        <template v-slot:filename>
          <span v-html="$tools.highlightKeywords('ppt1966描述',196)"></span>
        </template>
      </CommunicationRecordMsgUnit>
    </div>
    
  </div>
</template>
<script>
import CommunicationRecordMsgUnit from '@/components/communicationRecord/msgUnit.vue';
export default {
  components: {
    CommunicationRecordMsgUnit,
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
.communication_record_container{
  @include hidescrollbar;
  @include bbox;
  padding: 30px 60px;
  background-color:#F5F8FA;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .unit{
    @include bbox;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid $border_color;
    &:first-child{
      padding-top:0;
    }
    &:last-child{
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>