var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact_apply_box" }, [
    _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("添加联系人")]),
      _c("img", {
        staticClass: "close cp",
        attrs: { src: require("@/assets/images/common/close.png"), alt: "" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.closeAddFriend.apply(null, arguments)
          },
        },
      }),
    ]),
    _c("div", { staticClass: "search_group" }, [
      _c(
        "div",
        { staticClass: "search_box" },
        [
          _c("img", {
            staticClass: "search_icon cp",
            attrs: {
              src: require("@/assets/images/common/search_icon.png"),
              alt: "",
            },
          }),
          _c("el-input", {
            ref: "input",
            staticClass: "search",
            attrs: { placeholder: "通过电话号码搜索平台用户", maxlength: "11" },
            on: { input: _vm.inputChange },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.doSearch.apply(null, arguments)
              },
              paste: function ($event) {
                return _vm.pastePhone($event)
              },
            },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.input,
                expression: "input",
              },
            ],
            staticClass: "clear_icon cp",
            attrs: {
              src: require("@/assets/images/common/clear_icon.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.input = ""
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "container_group" }, [
      _c(
        "div",
        { staticClass: "container_box" },
        [
          _c("ContactApplyBoxContent", {
            attrs: { status: _vm.status, result: _vm.result },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }