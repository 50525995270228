<!--
 * @Author       : Hugo
 * @Date         : 2021-06-08 14:26:06
 * @LastEditTime : 2021-06-10 16:30:37
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/CommonWrapper.vue
 * @Description  : 弹层公共外包围
 * @^_^          : 
-->
<template>
  <div class="common_wrapper">
    <div src="~@/assets/images/common/close.png" alt="" class="close cp"
      @click="doClose()"
    ></div>
    <remove-chat-user
      v-if="popup_common_type=='remove_chat_user'"
      ref="remove_chat_user"
    ></remove-chat-user>
  </div>
</template>
<script>
/**
 * 说明:
 * ******
 * 子组件:
 *  子组件的doClose不关闭弹层, 只执行子组件关闭时需要的操作
 */
import RemoveChatUser from '@/components/PopUp/CommonType/RemoveChatUser.vue';
export default {
  components: {
    RemoveChatUser,
  },
  computed: {
    popup_common_type(){
      return this.$store.state.popup_common_type;
    }
  },
  methods: {
    async doClose(){
      await this.$refs[this.popup_common_type].doClose();
      this.$store.commit('set_switch_popup_common', false);
    }
  }
}
</script>
<style lang="scss" scoped>
.common_wrapper{
  // @include flex;
  // justify-content: center;
  position: relative;
  // max-height: 800px;
  // max-height: 200px;
  // max-width: 60%;
  // width: 100%;
  height: 800px;
  // overflow: hidden;
  .close{
    @include background(12px 12px);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    background-image: url(~@/assets/images/common/close.png);
  }
}
</style>
