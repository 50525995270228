<!--
 * @Author       : Hugo
 * @Date         : 2020-05-21 13:58:06
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-03-29 14:42:29
 * @Description  : 
 * @FilePath     : /miaohang/src/components/createDialog/selectedUnit.vue
--> 
<template>
  <div class="create_dialog_selected_unit">
    <div class="name_group">
      <img :src="`${$avatar_url}${selectedData.userdata.thumbnail}@!small200`" alt="" class="avatar">
      <div class="name">{{selectedData.userdata.friendRemark ? selectedData.userdata.friendRemark : selectedData.userdata.userName}}</div>
    </div>
    <div class="select_box" v-if="!selectedData.userdata.isInChat || is_single_dialog">
      <div class="select cp" @click="del"></div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    selectedData: {
      type: Object,
      default: null,
    }
  },
  computed: {
    /**是否单聊页面 */
    is_single_dialog(){
      let routename = this.$route.name;
      if(routename == 'fun_task_dialog_single'){
        return true;
      }
      return false;
    },
  },
  watch: {
    selectedData: function(){},
  },
  methods:{
    /**
     * 删除已选中
     */
    del(){
      this.$emit('del_selected', this.selectedData.father_index, this.selectedData.child_index);
    }
  }
}
</script>
<style lang="scss" scoped>
.create_dialog_selected_unit{
  @include bbox;
  @include flexsb;
  @include transition;
  // background-color: #fff;
  width:100%;
  // padding: {
  //   left: 37px;
  //   right: 46px;
  //   top: 11px;
  //   bottom: 11px;
  // }
  // &:hover{
  //   background-color:#f4f4f4;
  // }
  .name_group{
    @include flex;
    @include bbox;
    width:100%;
    flex: 1;
    padding-right: 15px;
    overflow: hidden;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      flex-shrink: 0;
    }
    .name{
      @include bbox;
      padding-left: 16px;
      width:100%;
      flex:1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
  .select_box{
    flex-shrink: 0;
    width:18px;
    height:18px;
    .select{
      @include bbox;
      @include transition;
      width: 100%;
      height: 100%;
      border-radius:50%;
      border:none;
      background-image: url(~@/assets/images/create_dialog/delete.png);
      background-size: 100%;
    }
  }
}
@media screen and (max-height: 800px){
.create_dialog_selected_unit{
  .name_group{
    .avatar{
      width: 30px;
      height: 30px;
    }
  }
}
}
</style>