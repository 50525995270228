var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "control_contact_selected_wrapper" }, [
    _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("选择联系人")]),
      _c("img", {
        staticClass: "close cp",
        attrs: { src: require("@/assets/images/common/close.png"), alt: "" },
        on: {
          click: function ($event) {
            return _vm.$store.commit("set_switch_annc_selectbox", false)
          },
        },
      }),
    ]),
    _c("div", { staticClass: "search_group" }, [
      _c(
        "div",
        { staticClass: "search_box" },
        [
          _c("img", {
            staticClass: "search_icon cp",
            attrs: {
              src: require("@/assets/images/common/search_icon.png"),
              alt: "",
            },
          }),
          _c("el-input", {
            staticClass: "search",
            attrs: { placeholder: "搜索" },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          }),
          _c("img", {
            staticClass: "clear_icon cp",
            attrs: {
              src: require("@/assets/images/common/clear_icon.png"),
              alt: "",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "container_group" }, [
      _c(
        "div",
        { staticClass: "select_group" },
        [
          _vm.$route.name == "fun_task_dialog_group"
            ? _c("div", { staticClass: "top_title" }, [_vm._v("组内联系人")])
            : _vm._e(),
          _vm.$route.name == "fun_task_dialog_group"
            ? _vm._l(_vm.group_list, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: (_vm.input && item.searchName) || !_vm.input,
                        expression: "((input && item.searchName) || !input)",
                      },
                    ],
                    key: `group${index}`,
                    staticClass: "unit padding",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "create_dialog_select_unit",
                        class: { on: item.on },
                      },
                      [
                        _c("div", { staticClass: "name_group" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: `${_vm.$avatar_url}${item.thumbnail}`,
                              alt: "",
                            },
                          }),
                          _c("div", {
                            staticClass: "name",
                            domProps: {
                              innerHTML: _vm._s(
                                item.searchName
                                  ? item.searchName
                                  : item.friendRemark || item.userName
                              ),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "select_box" }, [
                          _c("div", {
                            staticClass: "select cp",
                            on: {
                              click: function ($event) {
                                return _vm.doSelect(item, "group")
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]
                )
              })
            : _vm._e(),
          _c("div", { staticClass: "top_title" }, [_vm._v("全部联系人")]),
          _vm._l(_vm.select_group, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: (_vm.input && item.searchName) || !_vm.input,
                    expression: "((input && item.searchName) || !input)",
                  },
                ],
                key: `index${index}`,
                staticClass: "unit padding",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "create_dialog_select_unit",
                    class: { on: item.on },
                  },
                  [
                    _c("div", { staticClass: "name_group" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: `${_vm.$avatar_url}${item.thumbnail}`,
                          alt: "",
                        },
                      }),
                      _c("div", {
                        staticClass: "name",
                        domProps: {
                          innerHTML: _vm._s(
                            item.searchName
                              ? item.searchName
                              : item.friendRemark || item.userName
                          ),
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "select_box" }, [
                      _c("div", {
                        staticClass: "select cp",
                        on: {
                          click: function ($event) {
                            return _vm.doSelect(item, "friend")
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "selected_group" },
        _vm._l(_vm.selected_group, function (item, index) {
          return _c("div", { key: index, staticClass: "unit padding" }, [
            _c("div", { staticClass: "create_dialog_selected_unit" }, [
              _c("div", { staticClass: "name_group" }, [
                _c("img", {
                  staticClass: "avatar",
                  attrs: {
                    src: `${_vm.$avatar_url}${item.thumbnail}`,
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.friendRemark || item.userName)),
                ]),
              ]),
              _c("div", { staticClass: "select_box" }, [
                _c("div", {
                  staticClass: "select cp",
                  on: {
                    click: function ($event) {
                      return _vm.delSelected(item, index)
                    },
                  },
                }),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "fun_group" }, [
      _c("div", { staticClass: "btn sure", on: { click: _vm.sure } }, [
        _vm._v("确定"),
      ]),
      _c(
        "div",
        {
          staticClass: "btn cancel",
          on: {
            click: function ($event) {
              return _vm.$store.commit("set_switch_annc_selectbox", false)
            },
          },
        },
        [_vm._v("取消")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }