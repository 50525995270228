<script>
import TaskInfo from '@/models/task/task_info'

export default {
  props: {
    taskInfo: {
      type: TaskInfo,
      default: () => {
        return {}
      }
    },
  },
  methods: {
    touchDelect () {
      this.$emit(
        'touchDelect',
        this.taskInfo
      )
    }
  }
}
</script>

<template>
<div
  class="teaching_task bbox_wrapper"
>
  <div
    class="close cp"
    @click.stop="touchDelect"
  ></div>
  <div
    class="title_group"
  >
    <div
      class="title"
    >
      {{ taskInfo.name }}
    </div>
  </div>
  <div
    class="info_group d-flex align-center justify-space-between"
  >
    <div
      class="name_group"
    >
      <img
        :src="`${ $avatar_url }${ taskInfo.createuserthumbnail || taskInfo.updateuserthumbnail }@!small200`"
        alt=""
        class="avatar"
      >
    </div>
    <div
      class="tag_group"
    >
      <div
        class="tag teach"
      >
        教
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.teaching_task {
  width: 100%;
  padding: 12px 16px;
  position: relative;
  .close {
    @include background(16px 16px);
    width: 20px;
    height: 20px;
    background-image: url(~@/assets/images/course_package/delete.png);
    position: absolute;
    z-index: 9;
    top: -9px;
    right: -3px;
  }
  .title_group {
    .title {
      @include single_line_intercept;
      width: 100%;
    }
  }
  .info_group {
    width: 100%;
    padding-top: 7px;
    .name_group {
      .avatar {
        @include background;
        width: 20px;
        height: 20px;
        border-radius: 2px;
      }
    }
    .tag_group {
      .tag {
        width: 20px;
        height: 20px;
        text-align: center;
        font-size: 13px;
        border-radius: 4px;
        &.teach {
          background: rgba(255,114,0,0.08);
          color: #FF9D4C;
        }
      }
    }
  }

}
</style>
