import request from '@/api/request'
import tools from '@/api/tools'
class UserInfo {
  address
  applyStatus
  areditflag
  authStatus
  channelName
  chatUserName
  city
  coder
  createTime
  department
  email
  friendRemark
  hxPwd
  identity
  ischeckphones
  isfriend
  isgetgift
  isread
  listSkill
  loginName
  major
  openId
  openproject
  ownedEnterprises
  personalDescribe
  phone
  province
  provinceCoder
  punishNum
  resetpassword
  sign
  spaceBackgroundPath
  threeOne
  thumbnail
  updateTime
  updateremark
  userCardNo
  userId
  userIntegral
  userName
  userPassword
  userPosition
  userRealName
  userSex
  userStatus
  userToken
  userType
  userVip
  usercode
  vipDays
  vipEndDate
  vipStartDate

  constructor (params) {
    this.updateInfo(params)
  }

  updateInfo (params) {
    const {
      address,
      applyStatus,
      areditflag,
      authStatus,
      channelName,
      chatUserName,
      city,
      coder,
      createTime,
      department,
      email,
      friendRemark,
      hxPwd,
      identity,
      ischeckphones,
      isfriend,
      isgetgift,
      isread,
      listSkill,
      loginName,
      major,
      openId,
      openproject,
      ownedEnterprises,
      personalDescribe,
      phone,
      province,
      provinceCoder,
      punishNum,
      resetpassword,
      sign,
      spaceBackgroundPath,
      threeOne,
      thumbnail,
      updateTime,
      updateremark,
      userCardNo,
      userId,
      userIntegral,
      userName,
      userPassword,
      userPosition,
      userRealName,
      userSex,
      userStatus,
      userToken,
      userType,
      userVip,
      usercode,
      vipDays,
      vipEndDate,
      vipStartDate,
    } = params ?? {}
    this.address = address
    this.applyStatus = applyStatus
    this.areditflag = areditflag
    this.authStatus = authStatus
    this.channelName = channelName
    this.chatUserName = chatUserName
    this.city = city
    this.coder = coder
    this.createTime = createTime
    this.department = department
    this.email = email
    this.friendRemark = friendRemark
    this.hxPwd = hxPwd
    this.identity = identity
    this.ischeckphones = ischeckphones
    this.isfriend = isfriend
    this.isgetgift = isgetgift
    this.isread = isread
    this.listSkill = listSkill
    this.loginName = loginName
    this.major = major
    this.openId = openId
    this.openproject = openproject
    this.ownedEnterprises = ownedEnterprises
    this.personalDescribe = personalDescribe
    this.phone = phone
    this.province = province
    this.provinceCoder = provinceCoder
    this.punishNum = punishNum
    this.resetpassword = resetpassword
    this.sign = sign
    this.spaceBackgroundPath = spaceBackgroundPath
    this.threeOne = threeOne
    this.thumbnail = thumbnail
    this.updateTime = updateTime
    this.updateremark = updateremark
    this.userCardNo = userCardNo
    this.userId = userId
    this.userIntegral = userIntegral
    this.userName = userName
    this.userPassword = userPassword
    this.userPosition = userPosition
    this.userRealName = userRealName
    this.userSex = userSex
    this.userStatus = userStatus
    this.userToken = userToken
    this.userType = userType
    this.userVip = userVip
    this.usercode = usercode
    this.vipDays = vipDays
    this.vipEndDate = vipEndDate
    this.vipStartDate = vipStartDate
  }

  /**
   * 实名认证
   * @param data
   * @param data.identity
   * @param data.major
   * @param data.department
   * @param data.userRealName
   * @param data.ownedEnterprises
   * @param data.userCardNo
   * @returns {Promise<void>}
   */
  async realNameAuthentication (data) {
    const res = await request.request(
      '/project/authUser',
      data
    )
    if (res.message === 'success') {
      this.identity = data.identity
      this.major = data.major
      this.department = data.department
      this.userRealName = data.userRealName
      this.ownedEnterprises = data.ownedEnterprises
      this.userCardNo = data.userCardNo
      this.authStatus = 1
      tools.setCookie(
        'user',
        this
      )
      return true
    }
    return false
  }
}

export default UserInfo
