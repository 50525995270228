var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "communication_record_layout" }, [
    _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("搜索沟通记录")]),
      _c("img", {
        staticClass: "close cp",
        attrs: { src: require("@/assets/images/common/close.png"), alt: "" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            _vm.$emit("handleClose")
            _vm.$api.common_set_communication_record_box(false)
          },
        },
      }),
    ]),
    _c("div", { staticClass: "search_group" }, [
      _c(
        "div",
        { staticClass: "search_box" },
        [
          _c("img", {
            staticClass: "search_icon cp",
            attrs: {
              src: require("@/assets/images/common/search_icon.png"),
              alt: "",
            },
          }),
          _c("el-input", {
            staticClass: "search",
            attrs: { placeholder: "搜索" },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          }),
          _c("img", {
            staticClass: "clear_icon cp",
            attrs: {
              src: require("@/assets/images/common/clear_icon.png"),
              alt: "",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "container_group" }, [
      _c(
        "div",
        {
          directives: [
            { name: "show", rawName: "v-show", value: 0, expression: "0" },
          ],
          staticClass: "container",
        },
        [
          _c("img", {
            directives: [
              { name: "show", rawName: "v-show", value: 1, expression: "1" },
            ],
            staticClass: "default",
            attrs: {
              src: require("@/assets/images/template_search/default.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              { name: "show", rawName: "v-show", value: 0, expression: "0" },
            ],
            staticClass: "none",
            attrs: {
              src: require("@/assets/images/template_search/none.png"),
              alt: "",
            },
          }),
          _c(
            "p",
            {
              directives: [
                { name: "show", rawName: "v-show", value: 0, expression: "0" },
              ],
              staticClass: "hint",
            },
            [_vm._v("暂无搜索结果")]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            { name: "show", rawName: "v-show", value: 1, expression: "1" },
          ],
          staticClass: "container",
        },
        [_c("CommunicationRecordContainer")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "type_group" }, [
      _c("div", { staticClass: "type_box" }, [
        _c("div", { staticClass: "unit on" }, [_vm._v("全部")]),
        _c("div", { staticClass: "unit" }, [_vm._v("模板")]),
        _c("div", { staticClass: "unit" }, [_vm._v("文件")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }