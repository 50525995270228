var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "remove_chat_user d-flex align-stretch" }, [
    _c("div", { staticClass: "title" }, [_vm._v("选择移除的成员")]),
    _c("div", { staticClass: "remove_wrapper d-flex" }, [
      _c(
        "div",
        { staticClass: "select_group d-flex" },
        [
          _vm._l(_vm.dialog_user_list, function (item, index) {
            return [
              item && item.userId != _vm.create_userid
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item d-flex cp flex-shrink-0",
                      on: {
                        click: function ($event) {
                          return _vm.selectUser(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "info_group d-flex" }, [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "username" }, [
                          _vm._v(_vm._s(_vm.getUserName(item))),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "select cp",
                        class: { on: item.selected },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "turn_over_group d-flex" },
        [
          _c(
            "div",
            {
              staticClass: "item d-flex cp flex-shrink-0",
              on: {
                click: function ($event) {
                  return _vm.selectTurnOver(null)
                },
              },
            },
            [
              _vm._m(0),
              _c("div", {
                staticClass: "select cp",
                class: { on: _vm.remove_data.turn_over_user === null },
              }),
            ]
          ),
          _vm._l(_vm.select_list, function (item, index) {
            return [
              item && item.userId != _vm.to_remove_userid
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item flex-shrink-0 d-flex cp",
                      on: {
                        click: function ($event) {
                          return _vm.selectTurnOver(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "info_group d-flex" }, [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "username" }, [
                          _vm._v(_vm._s(_vm.getUserName(item))),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "select cp",
                        class: { on: item.turn_over },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _vm.switch_placeholder
            ? _c("div", { staticClass: "placeholder" })
            : _vm._e(),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "fun_group d-flex justify-center flex-shrink-0" },
      [
        _c(
          "button",
          {
            staticClass: "button btn_dark",
            on: {
              click: function ($event) {
                return _vm.doSure()
              },
            },
          },
          [_vm._v("确定")]
        ),
        _c(
          "button",
          {
            staticClass: "button btn_light",
            on: {
              click: function ($event) {
                return _vm.$parent.doClose()
              },
            },
          },
          [_vm._v("取消")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info_group" }, [
      _c("div", { staticClass: "username" }, [_vm._v("不指定交接人员")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }