<!--
 * @Author       : Hugo
 * @Date         : 2020-06-05 16:35:19
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-24 16:03:53
 * @Description  : 沟通记录布局
 * @FilePath     : /miaohang/src/components/communicationRecord/layout.vue
--> 
<template>
  <div class="communication_record_layout">
    <div class="title_group">
      <div class="title">搜索沟通记录</div>
      <img src="@/assets/images/common/close.png" alt="" class="close cp" @click.stop="$emit('handleClose');$api.common_set_communication_record_box( false)">
    </div>
    <div class="search_group">
      <div class="search_box">
        <img src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp">
        <el-input v-model="input" placeholder="搜索" class="search"></el-input>
        <img src="@/assets/images/common/clear_icon.png" alt="" class="clear_icon cp">
      </div>
    </div>
    <div class="type_group">
      <div class="type_box">
        <div class="unit on">全部</div>
        <div class="unit">模板</div>
        <div class="unit">文件</div>
      </div>
      
    </div>
    <div class="container_group">
      <div class="container" v-show="0">
        <img src="@/assets/images/template_search/default.png" alt="" class="default" v-show="1">
        <img src="@/assets/images/template_search/none.png" alt="" class="none" v-show="0">
        <p class="hint" v-show="0">暂无搜索结果</p>
      </div>
      <div class="container" v-show="1">
        <CommunicationRecordContainer></CommunicationRecordContainer>
      </div>
    </div>
  </div>
</template>

<script>
import CommunicationRecordContainer from '@/components/communicationRecord/container.vue';
export default {
  components: {
    CommunicationRecordContainer,
  },
  data(){
    return {
      input: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.communication_record_layout{
  @include bbox;
  @include flex;
  flex-direction: column;
  width:834px;
  height:768px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius:4px;
  overflow: hidden;
  .title_group{
    @include bbox;
    flex-shrink: 0;
    text-align: center;
    width:100%;
    position: relative;
    z-index: 2;
    padding-top:36px;
    .title{
      font-size:18px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      position: relative;
      z-index: 2;
    }
    .close{
      position: absolute;
      z-index: 3;
      top: 42px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
  }
  .search_group{
    flex-shrink: 0;
    padding: {
      top:39px;
      bottom: 30px;
    }
    .search_box{
      @include flexsb;
      @include bbox;
      padding: {
        left: 30px;
        right: 20px;
      }
      margin:0 auto;
      width:550px;
      height:40px;
      background:rgba(243,243,243,1);
      border-radius:20px;
      .search{
        border:none;
        background-color: transparent;
        .el-input__inner{
          border:none;
          background-color: transparent;
        }
      }
    }
  }
  .type_group{
    .type_box{
      @include flexsb;
      @include bbox;
      width: 510px;
      margin: 0 auto;
      .unit{
        @include bbox;
        cursor: pointer;
        padding: {
          left: 25px;
          right: 25px;
          bottom:17px
        }
        font-size:16px;
        
        font-weight:400;
        color:rgba(51,51,51,1);
        border-bottom: 2px solid transparent;
        &.on{
          border-bottom: 2px solid $main_blue;
        }
      }
    }
  }
  .container_group{
    @include bbox;
    @include flex;
    align-items: stretch;
    height:100%;
    max-height: 100%;
    flex:1;
    width:100%;
    overflow: hidden;
    border: {
      top: 2px solid #f0f0f0;
      bottom: 2px solid #f0f0f0;
    }
    .padding{
      padding: {
        top: 10px;
        bottom: 10px;
        left:37px;
        right:46px;
      }
    }
    .container{
      @include flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      .hint{
        font-size:14px;
        
        font-weight:400;
        color:rgba(153,153,153,1);
      }
    }
  }
}
</style>
<style lang="css">
  .communication_record_layout .search .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;
    
    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>
