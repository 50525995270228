var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create_new_package_add_template" }, [
    _c("div", { staticClass: "content_group" }, [
      _c(
        "div",
        { ref: "scrollBox", staticClass: "content_box" },
        [
          _vm._l(_vm.task_list, function (item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c("div", { staticClass: "info_group" }, [
                _c("div", { staticClass: "info" }, [_vm._v(_vm._s(item.name))]),
              ]),
              _c("div", { staticClass: "fun_group" }, [
                _c("div", {
                  staticClass: "select_box cp",
                  class: { on: item.inFlag },
                  on: {
                    click: function ($event) {
                      return _vm.clickTask(item)
                    },
                  },
                }),
                _c("div", { staticClass: "status" }, [
                  _vm._v(_vm._s(_vm._f("checkstatus")(item.checkstatus))),
                ]),
              ]),
            ])
          }),
          !_vm.nomore && _vm.loading
            ? _c(
                "div",
                { staticClass: "item loading" },
                [
                  _c("loading-icon", {
                    staticClass: "loading_icon",
                    attrs: { size: "30px" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.nomore
            ? _c("div", { staticClass: "nomore" }, [_vm._v("没有更多了")])
            : _vm._e(),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "fun_group" }, [
      _c(
        "div",
        {
          staticClass: "btn sure",
          class: { on: _vm.canSure },
          on: { click: _vm.doSure },
        },
        [_vm._v("确认")]
      ),
      _c(
        "div",
        {
          staticClass: "btn cancel",
          on: {
            click: function ($event) {
              return _vm.switchAddTemplate(false)
            },
          },
        },
        [_vm._v("取消")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }