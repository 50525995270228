<template>
  <div
    class="c_multitask_fun_bar_m_unit d-flex flex-column bbox cp"
  >
    <div class="name un_sel">{{name}}</div>
    <div class="icon close"
      @click.stop="handleDelete()"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    large_task: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    name(){
      const {large_task} = this
      const {otask, otask_config} = large_task
      console.log(large_task)
      if(otask && otask_config){
        if(otask_config.task_name !== ''){
          return otask_config.task_name
        }
        console.log(otask.task.name === '',  otask.task.name??'' !== '', otask.task.name)
        return otask.task.name === '' || (otask.task.name??'' === '') ? '未命名任务' : otask.task.name
      }
    },

  },
  methods: {
    handleDelete(){
      this.$emit('handleDelete', this.large_task)
    }
  }
}
</script>

<style lang="scss" scoped>
.c_multitask_fun_bar_m_unit{
  @include transition;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  padding: 10px 0 0;
  position: relative;
  z-index: 2;
  max-height: 200px;
  &.active, &:hover{
    background-color: #e0e0e0;
  }
  .name{
    @include single_line_intercept;
    writing-mode: vertical-rl;
    color: #555;
    font-family: 'MicroSoft YaHei';
  }
  .close{
    @include background(8px, 8px);
    width: 100%;
    height: 30px;
    background-image: url(~@/assets/images/multitask/fun_bar/close.png);

  }
}
</style>

