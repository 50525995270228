var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create_dialog_container" }, [
    _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
      ]),
      _c("img", {
        staticClass: "close cp",
        attrs: { src: require("@/assets/images/common/close.png"), alt: "" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.closeAddContactToDialogBox.apply(null, arguments)
          },
        },
      }),
    ]),
    0
      ? _c("div", { staticClass: "search_group" }, [
          _c(
            "div",
            { staticClass: "search_box" },
            [
              _c("img", {
                staticClass: "search_icon cp",
                attrs: {
                  src: require("@/assets/images/common/search_icon.png"),
                  alt: "",
                },
              }),
              _c("el-input", {
                staticClass: "search",
                attrs: { placeholder: "搜索" },
                model: {
                  value: _vm.input,
                  callback: function ($$v) {
                    _vm.input = $$v
                  },
                  expression: "input",
                },
              }),
              _c("img", {
                staticClass: "clear_icon cp",
                attrs: {
                  src: require("@/assets/images/common/clear_icon.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.input = ""
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "container_group" }, [
      _c(
        "div",
        { staticClass: "select_group" },
        [
          _c("div", { staticClass: "sticky_group bbox_wrapper" }, [
            _c("div", { staticClass: "search_wrapper" }, [
              _c("div", { staticClass: "search_group" }, [
                _c(
                  "div",
                  { staticClass: "search_box" },
                  [
                    _c("img", {
                      staticClass: "search_icon cp",
                      attrs: {
                        src: require("@/assets/images/common/search_icon.png"),
                        alt: "",
                      },
                    }),
                    _c("el-input", {
                      staticClass: "search",
                      attrs: { placeholder: "搜索" },
                      model: {
                        value: _vm.input,
                        callback: function ($$v) {
                          _vm.input = $$v
                        },
                        expression: "input",
                      },
                    }),
                    _c("img", {
                      staticClass: "clear_icon cp",
                      attrs: {
                        src: require("@/assets/images/common/clear_icon.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.input = ""
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "add_new_group padding cp",
                on: { click: _vm.goToApplyContact },
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: require("@/assets/images/common/add_contact_to_dialog.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [_vm._v("邀请新联系人")]),
              ]
            ),
          ]),
          0
            ? _c("div", { staticClass: "select_all_group padding cp" }, [
                _c("div", { staticClass: "text" }, [_vm._v("全选")]),
                _c("div", { staticClass: "icon" }),
              ])
            : _vm._e(),
          _vm._l(_vm.select_group, function (group_item, group_index) {
            return _c(
              "div",
              { key: group_index, staticClass: "firstname_group" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.input && _vm.getGroupUserLength(group_item),
                        expression: "!input && getGroupUserLength(group_item)",
                      },
                    ],
                    staticClass: "title padding",
                  },
                  [_vm._v(_vm._s(group_index))]
                ),
                _vm._l(group_item, function (unit_item, unit_index) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (_vm.input && unit_item.searchName) || !_vm.input,
                            expression:
                              "((input && unit_item.searchName) || !input)",
                          },
                        ],
                        key: `unit_index${unit_index}`,
                        staticClass: "unit padding",
                      },
                      [
                        _vm.add_contact_dialog_box_type == ""
                          ? [
                              (_vm.is_single_dialog &&
                                unit_item.friendUserId != _vm.chat_id) ||
                              !_vm.is_single_dialog
                                ? _c("CreateDialogSelectUnit", {
                                    attrs: {
                                      userData: unit_item,
                                      searchName: unit_item.searchName,
                                      on: unit_item.on || false,
                                      father_index: `${group_index}`,
                                      child_index: `${unit_index}`,
                                    },
                                    on: { doSelect: _vm.doSelect },
                                  })
                                : _vm._e(),
                            ]
                          : [
                              _c("CreateDialogSelectUnit", {
                                attrs: {
                                  userData: unit_item,
                                  searchName: unit_item.searchName,
                                  on: unit_item.on || false,
                                  father_index: `${group_index}`,
                                  child_index: `${unit_index}`,
                                },
                                on: { doSelect: _vm.doSelect },
                              }),
                            ],
                      ],
                      2
                    ),
                  ]
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "selected_group" },
        _vm._l(_vm.selected_group, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "unit" },
            [
              item.userdata.isInChat != 1 || _vm.is_single_dialog
                ? _c("CreateDialogSelectedUnit", {
                    staticClass: "padding",
                    attrs: { selectedData: item },
                    on: { del_selected: _vm.del_selected },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "fun_group justify-end" }, [
      _c(
        "button",
        {
          directives: [{ name: "preventReClick", rawName: "v-preventReClick" }],
          staticClass: "btn cancel",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.closeAddContactToDialogBox.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n      取消\n    ")]
      ),
      _c(
        "button",
        {
          directives: [{ name: "preventReClick", rawName: "v-preventReClick" }],
          staticClass: "btn sure",
          attrs: { disabled: _vm.banned_sure },
          on: { click: _vm.doSure },
        },
        [_vm._v("\n      确定\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }