<template>
  <el-container class="wrapper layout_default" >
    <!-- 边栏 -->
    <el-aside
      class="main_aside"
      :class="{
        active: extend_switch && open_extend,
        no_sidebar: $route.meta.hideSidebar
    }"
      @mouseover.native="mainAsideMouseOverHandler()"
      @mouseout.native="mainAsideMouseOutHandler()"
    >
      <el-container
        class="fun_aside"
      >
        <el-aside width="60px" class="fun_main">
          <fun-aside></fun-aside>
        </el-aside>
        <el-main
          v-if="!$route.meta.hideSidebar"
          class="fun_container"
        >
          <router-view
            name="sidebar"
          ></router-view>
        </el-main>
      </el-container>
      <div
        v-show="can_extend && open_extend"
        class="extend_switch cp un_sel"
        :class="{active: extend_switch}"
        @click="extend_switch=!extend_switch;"
      ></div>
    </el-aside>
    <!-- 内容 -->
    <el-container class="default_container ">
      <el-main class="default_main d-flex">
        <router-view class="router_view"></router-view>
        <multitask-fun-bar
          v-if="1"
          class="flex-shrink-0 multitask_fun_bar"
        ></multitask-fun-bar>

      </el-main>
    </el-container>

    <transition name="fade">
      <!-- 添加好友 -->
      <div
        class="add_friend_wrapper floor_fixed"
        v-if="add_friend_box"
        ref="add_friend_box"
        style="z-index: 2999"
      >
        <contact-apply-box v-if="search_friend"></contact-apply-box>
        <ContactSendApplycation v-if="add_friend" :result="add_friend_data"></ContactSendApplycation>
      </div>
    </transition>
    <!-- 添加联系人到会话 -->
    <transition name="fade">
      <div
        class="layout_default_contact_to_dialog_wrapper floor_fixed"
        v-if="add_contact_to_dialog_box"
        @click="closeAddContactToDialogBox"
        ref="add_contact_to_dialog_box"
      >
        <CreateDialogContainer @click.stop.native="stopPropagation($event)"></CreateDialogContainer>
      </div>
    </transition>
    <!-- 沟通记录 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="communication_record_box && 0" @click="$api.common_set_communication_record_box(false)" ref="communication_record_box">
        <CommunicationRecord @click.stop.native="stopPropagation($event)"></CommunicationRecord>
      </div>
    </transition>
    <!-- 创建模板 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="edit_task_box" ref="edit_task_box">
        <Task
        ></Task>
      </div>
    </transition>
    <!-- 编辑模板 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_task_box" ref="switch_task_box">
        <Task
        ></Task>
      </div>
    </transition>
    <!-- 创建/编辑教学包 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_package_box" ref="switch_package_box">
        <div class="create_new_package_wrapper">
          <create-new-package></create-new-package>
        </div>
      </div>
    </transition>
    <!-- 绑定识别码 -->
    <el-dialog
      title="收货地址"
      class="identify_group"
      :visible.sync="identify_group"
      :show-close="false"
      :close-on-click-modal="false"
      width="400px"
    >
      <div class="title_group d-flex align-center justify-center" slot="title">
        <div class="title">请输入识别码:</div>
      </div>
      <input type="text" class="identify" v-model="identify" placeholder="请输入识别码" @keyup="identify_hint=''">
      <div class="hint">{{identify_hint}}</div>
      <div slot="footer" class="footer_group">
        <div
          class="btn cp un_sel sure btn_dark"
          :class="{on: identify!=''}"
          @click="doIdentify"
        >
          确认
        </div>
        <div
          class="btn cp cancel btn_light"
          @click.stop="goToLogin()"
        >
          取消
        </div>
      </div>
    </el-dialog>
    <!-- 公示板 联系人控件 _选择联系人 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_annc_selectbox" ref="switch_annc_selectbox">
        <contact-selected-container></contact-selected-container>
      </div>
    </transition>
    <!-- 产品激活列表 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_acti_confirm" ref="switch_acti_confirm">
        <activation-confirm></activation-confirm>
      </div>
    </transition>
    <!-- 多选联系人 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_multiple_contacts" ref="switch_multiple_contacts">
        <multiple-contacts></multiple-contacts>
      </div>
    </transition>
    <!-- 分享确认 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_share_confirm" ref="switch_share_confirm">
        <share-confirm></share-confirm>
      </div>
    </transition>
    <!-- 教学包 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_teaching_package" ref="switch_teaching_package">
        <teaching-package></teaching-package>
      </div>
    </transition>
    <!-- 选择任务 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_select_tasks" ref="switch_select_tasks">
        <select-tasks></select-tasks>
      </div>
    </transition>
    <!-- 公共弹层 -->
    <transition name="fade">
      <div class="floor_fixed" v-if="switch_popup_common" ref="switch_popup_common">
        <pop-up-common></pop-up-common>
      </div>
    </transition>
    <modal
      name="taskEdit"
      height="90%"
      width="80%"
    >
      <ModelTask></ModelTask>
    </modal>
    <!-- 课程包 -->
    <modal
      name="globalCoursePackageEdit"
      height="90%"
      width="80%"
    >
      <CoursePackegeEdit></CoursePackegeEdit>
    </modal>
  <!-- 选择课程包任务  -->
    <modal
      name="globalCoursePackageChoiceTask"
      height="90%"
      width="80%"
    >
      <CoursePackageChoiceTask></CoursePackageChoiceTask>
    </modal>
  <!--   审核课程包 -->
    <modal
      name="globalCheckCrousePackage"
      height="90%"
      width="80%"
    >
      <CheckCoursePackage></CheckCoursePackage>
    </modal>
  <!--   查看课程包 -->
    <modal
      name="globalViewCrousePackage"
      height="90%"
      width="80%"
    >
      <CheckCoursePackage
        :show-top="true"
        :audit-mode="false"
        :view-mode="true"
      ></CheckCoursePackage>
    </modal>
  <!--  删除课程包 -->
    <modal
      name="globalDeleteCoursePackage"
      height="240px"
      width="340px"
    >
      <JsModal
        :title="'删除课程'"
        @closeHandler="$modal.hide('globalDeleteCoursePackage')"
      >
        <DeleteCoursePackageConfirm></DeleteCoursePackageConfirm>
      </JsModal>
    </modal>
    <!-- 不通过原因 -->
    <modal
      name="globalCoursePackageFailReason"
      ref="globalCoursePackageFailReason"
      width="340px"
      height="auto"
      :clickToClose="false"
      @before-open="beforeOpenModal('globalCoursePackageFailReason')"
      @opened="openModal('globalCoursePackageFailReason')"
    >
      <JsModal
        :title="'未通过原因'"
        @closeHandler="$modal.hide('globalCoursePackageFailReason')"
      >
        <CrousePackageFailReason></CrousePackageFailReason>
      </JsModal>
    </modal>
    <!--  补充实名认证 -->
    <modal
      name="globalAdditionInformation"
      ref="globalAdditionInformation"
      width="1000px"
      height="auto"
      :clickToClose="false"
    >
      <AdditionInformation></AdditionInformation>
    </modal>
  </el-container>

</template>
<script>
import FunAside from '@/components/default/funAside.vue';
import ContactApplyBox from '@/components/Contact/ApplyBox.vue';
import ContactSendApplycation from '@/components/Contact/SendApplycation.vue';
import CreateDialogContainer from '@/components/createDialog/container.vue';
import CommunicationRecord from '@/components/communicationRecord/layout.vue';
// import DialogEditTaskLayout from '@/components/dialog/EditTask/Layout.vue';
import Task from '@/components/Task/Container.vue';
import CreateNewPackage from '@/components/FunTemplate/CreateNewPackage.vue';
import ContactSelectedContainer from '@/components/AnnouncementControl/ContactSelectedContainer.vue';
import ActivationConfirm from '@/components/PersonalCenter/ActivationConfirm.vue';
import MultipleContacts from '@/components/PopUp/MultipleContacts.vue';
import ShareConfirm from '@/components/PopUp/ShareComfirm.vue';
import TeachingPackage from '@/components/PopUp/TeachingPackage.vue';
import SelectTasks from '@/components/PopUp/SelectTasks.vue';
import $expand from '@/api/expand';
import PopUpCommon from '@/components/PopUp/CommonWrapper.vue';
import vue from '@/main.js';
import MultitaskFunBar from '@/components/Multitask/FunBar/index.vue'
import tools from '@/api/tools'
import ModelTask from '@/components/resource_library/teaching_task/index.vue'
import CoursePackegeEdit from '@/components/resource_library/course_package/edit/index'
import CoursePackageChoiceTask from '@/components/resource_library/course_package/choice_task/index'
import JsModal from '@/components/JsModal'
import CheckCoursePackage from '@/components/resource_library/course_package/check/index'
import DeleteCoursePackageConfirm from '@/components/resource_library/course_package/delete_confirm'
import CrousePackageFailReason from '@/components/resource_library/course_package/check/FailReason'
import AdditionInformation from '@/views/Login/addition_information'
import store from '@/store'
import { mapState } from 'vuex'
import UserInfo from '@/models/common/user_info'
// todo 清理生成watch和computed数据
let setZIndex = function(ref_name){
  vue.$nextTick(function(){
    const {default_floor_z_index} = vue.$store.state;
    vue.$store.commit('set_default_floor_z_index', default_floor_z_index+1);
    vue.$refs[ref_name].style.zIndex = default_floor_z_index+1;
  })

}

/**
 * 生成自定义zindex的watch数据
 * @param {Array} params ['computed字段名']
 * @param {Function} fun 执行函数
 * @returns
 */
function generateZIndexWatch(params, fun){
  // // console.log(params, fun)
  let watch = {};
  if(params && params.length){
    params.forEach( item => {
      if(item){
        watch[item] = (val) => {
          // console.log('watch', fun, val)
          // console.log(this);
          if(val){
            fun.bind(this,item);
            fun(item)
          }
        };
      }
    })
  }
  // // console.log(watch)
  return watch;
}
// 自定义computed
const customComputed = {
}
// 生成computed
const generateComputed= $expand.generateComputed([
  {name:'add_friend_box', modules: 'task_module'}, //添加好友弹层
  {name:'search_friend', modules: 'task_module'},  //搜索用户
  {name:'add_friend', modules: 'task_module'}, // 添加好友
  {name:'add_friend_data', modules: 'task_module'}, // 被添加好友数据
  {name:'add_contact_to_dialog_box', modules: ''}, // 添加联系人到会话容器
  {name:'communication_record_box', modules: ''}, // 沟通记录
  {name:'edit_task_box', modules: ''}, //打开/关闭 编辑任务模板
  {name:'switch_package_box', modules: ''}, // 开关 教学包盒子
  {name:'switch_task_box', modules: ''}, // 开关 编辑模板2
  {name:'switch_annc_selectbox', modules: ''}, // 联系人组件选择盒子
  {name:'switch_acti_confirm', modules: ''}, // 确认激活码内容
  {name:'switch_multiple_contacts', modules: ''}, // 多选联系人
  {name:'switch_share_confirm', modules: ''}, // 分享确认
  {name:'switch_teaching_package', modules: ''}, // 教学包分享盒子 开关
  {name:'switch_select_tasks', modules: ''},  // 选择任务
  {name:'switch_popup_common', modules: ''},  // 公共弹层
]);

export default {
  components:{
    FunAside,
    ContactApplyBox,
    ContactSendApplycation,
    CreateDialogContainer,
    CommunicationRecord,
    // DialogEditTaskLayout,
    CreateNewPackage,
    ContactSelectedContainer,
    ActivationConfirm,
    MultipleContacts,
    ShareConfirm,
    TeachingPackage,
    SelectTasks,
    PopUpCommon,
    Task,
    MultitaskFunBar,
    ModelTask,
    CoursePackegeEdit, // 课程包
    CoursePackageChoiceTask, // 选择课程包任务
    JsModal,
    CheckCoursePackage, // 查看课程包/审核
    DeleteCoursePackageConfirm, // 删除课程包 确认框
    CrousePackageFailReason, // 未通过原因
    AdditionInformation, // 实名认证
  },
  computed: Object.assign(generateComputed, customComputed, {
    /**
     * 是否开启拓展侧边栏功能
     */
    open_extend(){
      const route       = this.$route;
      const {path}      = route;
      const open_arr    = ['fun_contact', 'fun_task' ];
      let   open_extend = false;
      open_arr.forEach(item=>{
        if(path.indexOf(item) != -1){
          open_extend = true;
        }
      })
      return open_extend;
    },
    ...mapState({
      appUserInfo: state => state.app.userInfo,
    }),
  }),
  watch: {
    switch_task_box: function(val){
      if(!val){
        this.taskBoxCloseHandler();
      }
      if(val){
        this.setZIndex('switch_task_box');
      }
    },
    switch_teaching_package: function(val){
      if(val){
        this.setZIndex('switch_teaching_package');
      }
    },
    add_friend_box: function(val){
      // console.log(val);
      if(val){
        this.setZIndex('add_friend_box');
      }
    },
    search_friend: function(val){
      if(val){
        // this.setZIndex('search_friend');
      }
    },
    add_friend: function(val){
      if(val){
        // this.setZIndex('add_friend');
      }
    },
    add_friend_data: function(val){
      if(val){
        this.setZIndex('add_friend_data');
      }
    },
    add_contact_to_dialog_box: function(val){
      if(val){
        this.setZIndex('add_contact_to_dialog_box');
      }
    },
    communication_record_box: function(val){
      if(val){
        this.setZIndex('communication_record_box');
      }
    },
    edit_task_box: function(val){
      if(val){
        this.setZIndex('edit_task_box');
      }
    },
    switch_package_box: function(val){
      if(val){
        this.setZIndex('switch_package_box');
      }
    },
    switch_annc_selectbox: function(val){
      if(val){
        this.setZIndex('switch_annc_selectbox');
      }
    },
    switch_acti_confirm: function(val){
      if(val){
        this.setZIndex('switch_acti_confirm');
      }
    },
    switch_multiple_contacts: function(val){
      if(val){
        this.setZIndex('switch_multiple_contacts');
      }
    },
    switch_share_confirm: function(val){
      if(val){
        this.setZIndex('switch_share_confirm');
      }
    },
    switch_popup_common: function(val){
      if(val){
        this.setZIndex('switch_popup_common');
      }
    },
  },
  data(){
    return {
      identify_group: false,
      identify      : '',
      identify_hint : '',
      extend_switch : false,   // 拓展状态
      can_extend    : false,   // 是否显示拓展
      timeout_extend: null,   // 延时关闭拓展按钮
    }
  },
  mounted(){
    const user = tools.getCookie('user')
    console.log(user)
    const { usercode } = user
    if (!usercode) {
      this.identify_group = true
      return
    }
    this.initAppUserInfo()
    this.needAdditionInformation()
    // console.log(user)
    // if (!this.appUserInfo) {
    //   store.commit('setAppUserInfo', new UserInfo(user))
    // }
    // console.log(this.appUserInfo)

    // 剪切粘贴测试
    //  // console.log(document);
    // document.addEventListener("paste", function (event) {
    //   // console.log(event)
    //   if (event.clipboardData || event.originalEvent) {
    //       var clipboardData = (event.clipboardData || window.clipboardData);
    //       // console.log(clipboardData)
    //       var val = clipboardData.getData('text');
    //       // console.log(val);
    //       event.preventDefault();
    //   }
    // });

    // document.addEventListener("copy",function (event) {
    //   // console.log(event)
    //   if (event.clipboardData || event.originalEvent) {
    //       var clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
    //       // const selection = "AAAAA";
    //       // clipboardData.setData('text/plain', selection.toString());
    //       // console.log(clipboardData.getData('text'))
    //       event.preventDefault();
    //   }
    // });



    // let user = this.$tools.getCookie('user');
    // // console.log(user.usercode);
    // if(!user.usercode){
    //   // 未绑定校验码
    //   this.identify_group = true;
    // }
    // this.$openVideo({src: `${this.$file_url}2020/09/17/1600311363455553079.mp4`});
    // setTimeout(()=>{
    //   this.$openVideo({src: `${this.$file_url}2020/09/17/1600311396578901503.mp4`});
    // },2000)

    this.getReminderList()
    // this.$buy_now();
    // this.$scan_code();
    // this.$view_keys({title: 'ttt'});

    // this.$api.app.labelPageActivationEventListener();

    // 添加至清单测试
    // this.$add_to_custom_list();

    // prompt 测试
    // this.$openPrompt({
    //   title: '重命名',
    //   placeholder: '未命名',
    //   sure: '确认',
    // })

    // this.$task();


    // this.$openInvivationPoster()

    // this.$openNotMember({
    //   hint: '“智慧桌”控件是“智慧项目管理实训桌”硬件设备的配套控件，使老师能够在课堂上带领学生进行实践任务的布置、演练与计时，此控件仅限VIP会员使用。'
    // })

    // 【测试】教学包选择任务
    // this.$openTeachingPackageSelectTask()
    // this.$openConfirm({
    //   title:'是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏',
    //   desc:'是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏是否同时开始投屏?',
    //   sure:'发布任务且投屏',
    //   sureType: '', // 确定按钮样式 '','warn'
    //   cancel:'只发布任务',
    //   onSure:()=>{},
    //   onCancel:()=>{},
    // })


  },
  methods:{
    initAppUserInfo () {
      const user = tools.getCookie('user')
      if (!this.appUserInfo) {
        store.commit('setAppUserInfo', new UserInfo(user))
      }
    },
    needAdditionInformation () {
      const {
        major,
        department,
        authStatus,
      } = this.appUserInfo
      console.log(major, department)
      if (!authStatus) {
        this.$modal.show('globalAdditionInformation')
      }
    },
    getZIndex () {
      const { default_floor_z_index } = store.state
      store.commit(
        'set_default_floor_z_index',
        default_floor_z_index + 1
      )
      return default_floor_z_index + 1
    },
    beforeOpenModal (modalName) {
      console.log('beforeOpenModal')
      // console.log(modalName)
      // console.log(this.$refs[modalName])
      // console.log(this.$refs[modalName].$el)
      // this.$refs[modalName].$el.style.zIndex = this.getZIndex()
    },
    openModal (modalName) {
      console.log('opened')
      this.$refs[modalName].$el.style.zIndex = this.getZIndex()
    },
    goToLogin() {
      this.identify_group = false
      this.$router.push({ name: 'login_sign' })
    },
    /**
     * @description: 关闭添加好友弹窗
     * @param {}
     * @return:
     */
    closeAddContactToDialogBox(){
      this.$store.commit('set_add_contact_to_dialog_box', false);
    },
    /**
     * @description: 阻止冒泡
     * @param {type}
     * @return:
     */
    stopPropagation(e){
      e.stopPropagation();
    },
    init(){
      this.identify = '';
    },
    /**识别认证 */
    async doIdentify(){
      if(this.identify == ''){
        this.identify_hint = '* 请输入识别码';
      }else{
        let res = await this.$http.request('/project/bindUserCode', {usercode: this.identify});
        if(res.message != 'success'){
          this.identify_hint = `* ${res.describe}`;

        }else{
          this.identify_hint = '';
          this.identify_group = false;
          this.init();
          // cookie 设置为true
          let user = this.$tools.getCookie('user');
          user.usercode = true;
          this.$cookies.set('user', user);
        }
      }
    },
    /**
     * @description: 移入侧边时显示按钮
     * @param {*}
     * @return {*}
     */
    mainAsideMouseOverHandler(){
      // let {extend_switch} = this;
      // if(!extend_switch){
        clearTimeout(this.timeout_extend); // [关闭]延时关闭
        this.can_extend = true;
      // }

    },
    /**
     * @description: 移除侧边时判断是否展开
     * @param {*}
     * @return {*}
     */
    mainAsideMouseOutHandler(){
      let {extend_switch} = this;
      if(!extend_switch){
        // [打开]延迟关闭
        this.timeout_extend = setTimeout(()=>{
          this.can_extend = false;
        }, 1000);
      }
    },
    /**
     * @description: 关闭时移除心跳key
     * @param {*}
     * @return {*}
     */
    async removeHeartbeat(id){
      // let res;
      // try{
      //   res = await this.$http.request('/projectNews/removetaskMessageheartbeat', {taskId: id});
      // }catch(err){
      //   // console.log(err);
      // }
      this.$api.task.removeHeartbeat(id);
    },
    /**
     * @description: 任务盒子关闭
     * @param {*}
     * @return {*}
     */
    taskBoxCloseHandler(){
      // 关闭心跳定时
      const {task_chat_heartbeat_interval, task_data, task_chat_heartbeat_status} = this.$store.state;
      clearInterval(task_chat_heartbeat_interval);
      if(task_chat_heartbeat_status){
        // 移除心跳
        if(task_data && task_data.id){
          this.removeHeartbeat(task_data.id);
        }
        this.$store.commit('set_task_chat_heartbeat_status', false);
      }

      // 重置任务面板
      this.$store.commit('set_task_pannel', 'control');
      // 重置未读消息
      this.$store.commit('set_task_unreader_chat', false);
    },
    /**
     * @description: 弹层动态zindex
     * @param {*} ref_name
     * @return {*}
     */
    setZIndex(ref_name){
      // // console.log(ref_name)
      this.$nextTick(function(){
        const {default_floor_z_index} = this.$store.state;
        // // console.log(default_floor_z_index)
        this.$store.commit('set_default_floor_z_index', default_floor_z_index+1);
        // // console.log(this.$refs, this, ref_name)
        this.$refs[ref_name].style.zIndex = default_floor_z_index+1;
      })
    },
    async getReminderList(){
      const res = await this.$api.reminder.getReminderList();
    }


  },
}
</script>
<style lang="scss" scoped>
.wrapper{
  height: 100vh;
  .main_aside{
    @include transition;
    position: relative;
    width: 60+2+250px!important;
    padding:0;
    border-right: 2px solid $border_color;
    overflow: unset;
    z-index: 4;
    &.no_sidebar {
      width: 60 + 2px!important;
    }
    &.active{
      width: 60+2+250+200px!important;
    }
    .fun_aside{
      height:100%;
      justify-content: space-between;
      .fun_main{
        width: 60px;
        flex-shrink: 0;
        background-color: $main_blue;
      }
      .fun_container{
        @include bbox;
        padding-left:2px;
        // width: 252px;
        width: 100%;
        // flex: 1;
        padding: 0;
        // overflow: hidden;
      }
    }
    .extend_switch{
      @include background;
      @include transition;
      position: absolute;
      z-index: 2;
      right: -38px;
      top: 50%;
      width: 38px;
      height: 73px;
      margin-top: -36px;
      background: {
        image: url(~@/assets/images/layout/sidebar_open.png);
      }
      &.active{
        background: {
          image: url(~@/assets/images/layout/sidebar_close.png);
        }
      }
    }
  }
  .default_container{
    background-color:#F5F8FA;
    background: {
      image: url(~@/assets/images/common/logo.png);
      size: 88px 88px;
      position: center center;
      repeat: no-repeat;
    }
    position: relative;
    .default_main{
      padding:0;
      position: relative;
      .router_view{
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;
        z-index: 3;
        box-shadow: 0px 10px 20px rgb(0 0 0 / 50%);
      }
      .add_friend_wrapper{
        @include bbox;
        @include flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2090;
        background-color: rgba(0,0,0,0.3);
      }
      .multitask_fun_bar{
        height: 100%;
        z-index: 2;
        position: relative;
      }
    }
  }
  .layout_default_contact_to_dialog_wrapper{
    @include flex;
    justify-content: center;
    position: fixed;
    width:100%;
    height: 100%;
    z-index: 9;
  }
  // 创建模板
  .edit_task_group{
    width: 100%;
    height: 100%;
    max-width: 70%;
    // min-width: 1000px;
    // max-height: 80%;
    max-height: 1000px;
    // min-height: 800px;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
  }
  // 创建教学包
  .create_new_package_wrapper{
    width: 100%;
    height: 100%;
    max-width: 70%;
    min-width: 1000px;
    max-height: 80%;
    // min-height: 800px;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
  }
  // 弹层容器 fixed
  .floor_fixed{
    @include flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba( 0,0,0,0.3);
  }
  // 识别码
  .identify_group{
    .title_group{
      // width: 100%;
      // text-align: center;
      // font-size:18px;

      // font-weight:400;
      // color:rgba(51,51,51,1);
      // padding-top:49px;
      // padding-bottom: 30px;
      height: 46px;
      background: #F8F8F8;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .identify{
      @include bbox;
      padding: 0 20px;
      display: block;
      margin: 0 auto;
      width:360px;
      height:46px;
      background:rgba(251,251,251,1);
      border:1px solid rgba(240,240,240,1);
      border-radius:4px;
      outline: none;
    }
    .hint{
      width:360px;
      font-size:14px;

      font-weight:400;
      color:rgba(216,46,13,1);
      margin: 0 auto;
      height: 40px;
      line-height: 40px;
      margin-bottom: -34px;
    }
    .footer_group{
      @include flexsb;
      margin: 0 auto ;
      width: 360px;
      padding-bottom: 30px;
      .btn{
        @include transition;
        text-align: center;
        width:48%;
        height:50px;
        background:rgba(204,204,204,1);
        border-radius:4px;
        line-height: 50px;
        font-size:18px;

        font-weight:400;
        color:rgba(255,255,255,1);
        &.sure.on{
          background-color: $main_blue;
        }
        &.cancel{
          background-color: rgba(0,84,167,0.1);
          color: $main_blue;
        }

      }
    }

  }
}
</style>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
