var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact_apply_box_content" }, [
    _c("div", { staticClass: "wrapper" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.status == "default",
              expression: "status == 'default'",
            },
          ],
          staticClass: "default_group group",
        },
        [
          _c("img", {
            staticClass: "default",
            attrs: {
              src: require("@/assets/images/common/default.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.status == "none",
              expression: "status == 'none'",
            },
          ],
          staticClass: "group none_group",
        },
        [
          _c("img", {
            staticClass: "none",
            attrs: { src: require("@/assets/images/common/none.png"), alt: "" },
          }),
          _c("div", { staticClass: "hint" }, [_vm._v("暂无搜索结果")]),
        ]
      ),
      _vm.status == "result" && _vm.result != null
        ? _c("div", { staticClass: "group result_group" }, [
            _c("div", { staticClass: "result_box" }, [
              _c("div", { staticClass: "info_group" }, [
                _c("img", {
                  staticClass: "avatar",
                  attrs: {
                    src: `${_vm.$avatar_url}${_vm.result.thumbnail}`,
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "name_group" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.result.userName)),
                  ]),
                  _c("div", { staticClass: "desc" }, [
                    _vm._v(_vm._s(_vm.result.phone)),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.can_apply,
                      expression: "can_apply",
                    },
                  ],
                  staticClass: "fun_group",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "fun cp",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.goToAddFriend.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("申请")]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }