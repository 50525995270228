<!--
 * @Author       : Hugo
 * @Date         : 2021-04-13 15:38:12
 * @LastEditTime : 2021-09-27 12:16:09
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/TeachingPackage.vue
 * @Description  : 教学包弹窗
 * @^_^          : 
-->
<template>
  <div
    class="teaching_package_page d-flex flex-column"
  >
    <div class="sidebar_group bbox d-flex justify-space-between align-center">
      <template>
        <div
          class="from"
        ></div>
      </template>
      <div
        class="d-flex flex-align"
      >
        <el-popover
          placement="bottom-end"
          trigger="click"
          v-model="switch_menu"
          :visible-arrow="false"
          popper-class="teaching_package_page_menu_poppver"
        >
          <div class="menu_list">
            <div class="item cp"
              @click="doShare();"
            >分享</div>
          </div>
          <template
            slot="reference"
          >
            <div class="menu cp"
              @click="handleMenuClick();"
            ></div>
          </template>
         
        </el-popover>
        
        <div class="close_group cp"
          @click.stop="doClose();"
        ></div>
      </div>
    </div>

    <div class="container">
      <div class="buy_group"
        v-if="is_buy"
      >
        <img src="~@/assets/images/package/is_buy.png" alt="" class="is_buy">
      </div>
      <div class="header_group bbox d-flex align-center justify-space-between"
        v-if="package_data"
      >
        <div class="info_wrapper">
          <div class="title_group">{{package_data.title}}</div>
          <div class="picture_group"
            v-if="package_data.webpic"
          >
            <img 
              :src="`${$img_url}${package_data.webpic}`"
              alt=""
              class="picture"
            >
          </div>
          <div class="info_group">
            <div class="descript_group">
              <!-- <div class="title">简介</div> -->
              <div class="descript" v-html="package_data.content">简介内容</div>
            </div>
            
          </div>
        </div>
        <div class="purchase_group bbox d-flex flex-column justify-center"
          v-if="!is_buy"
        >
          <div class="title"
            v-if="price_status"
          >普通用户购买</div>
          <div class="price_group d-flex align-end"
            v-if="price_status"
          >
            <div class="symbol">¥ </div>
            <div class="price">{{package_data.price ? package_data.price/ 100 : 1000}}</div>
          </div>
          <div class="hint_group"
            v-if="price_status && 0"
          >成为<span class="mark">VIP</span>用户,立减<span class="mark">30</span>元</div>
          <button class="btn_dark button free"
            v-show="status_free_btn"
            @click="doBuyForFree();"
          >免费领取</button>
          <div class="fun_group d-flex align-center un_sel"
            v-if="buy_status"
          >
            <div class="button btn_dark buy_now"
              v-if="0"
            >立即购买</div>
            <div class="button consulting bbox d-flex align-center justify-center"
              @click="touchConsulting();"
            >
              <img 
                src="~@/assets/images/package/consult.png" alt="" class="icon">
              <div class="text">咨询</div>

            </div>
            

          </div>
          <div class="maker"
            v-if="maker_status"
          >您是制作者</div>
        </div>
        
        
      </div>
      <div class="task_list_group">
        <div class="title">教学模版列表（{{task_list.length}}）：</div>
        <div class="list_group">
          <template
            v-for="(item, index) in task_list"
          >
            <!-- <div class="unit cp un_sel"
              :key="index"
              @click="openTask(item);"
            >
              <div class="name_group">
                <div class="name">{{item.name}}</div>
              </div>
              <div class="price_group"
                v-if="1"
              >
                <div class="price">¥{{(item.price/100).toFixed(2)}}</div>
              </div>
            </div> -->
            <thumb-task
              :key="index"
              :item="item"
              class="unit cp un_sel"
              :menu="false"
              :show_time="false"
              :show_tag="false"
              @handleClick="openTask(item)"
              :separate="false"
            ></thumb-task>
          </template>
        </div>
      </div>
    </div>
    
    <consulting
      v-if="switch_consulting"
      :param_id="Number(package_id)"
      @handleClose="closeConsulting"
    ></consulting>
  </div>
</template>
<script>
import ThumbTask from '@/components/Common/ThumbTask.vue';
import Consulting from '@/components/PopUp/Consulting.vue';
export default {
  components: {
    Consulting,
    ThumbTask
  },
  data(){
    return {
      package_id: this.$store.state.teaching_package_id,
      package_data: {},
      task_list: [],
      switch_menu: false,
      switch_consulting: false,
    }
  },
  computed: {
    is_buy(){
      const {buy} = this.package_data;
      return buy;
    },
    is_free(){
      const {price} = this.package_data;
      if(price == 0){
        return true;
      }
      return false;
    },
    is_sign(){
      const {signUserId} = this.package_data;
      const userid = this.$tools.getUserId();
      if(signUserId == userid){
        return true;
      }
      return false;
    },
    // 免费领取按钮
    status_free_btn(){
      const {is_buy, is_free, is_sign} = this;
      if(!is_buy && is_free){
        if(!is_sign){
          return true;
        }
      }
      return false;
    },
    // 购买栏状态
    status_purchase_group(){
      const {is_buy, is_sign} = this;
      if(is_sign){
        return false;
      }
      return is_buy;
    },
    price_status(){
      const {is_free} = this;
      if(!is_free){
        return true;
      }
      return false;
    },
    buy_status(){
      const {is_sign, is_free} = this;
      if(!is_free && !is_sign){
        return true;
      }
      return false;
    },
    maker_status(){
      const {is_sign} = this;

      if(is_sign){
        return true;
      }
      return false;
    }

  },
  methods: {
    /**
     * @description: 关闭
     * @param {*}
     * @return {*}
     */
    doClose(){
      this.$store.commit('set_switch_teaching_package', false);
    },
    /**
     * @description: 获取教学包信息
     * @param {*}
     * @return {*}
     */
    async getTeachingPackageData(){
      // const {commit, state} = this.$store;
      // const {teaching_package_id} = state; 
      const {package_id} = this;
      let res;
      if(package_id){
        res = await this.$global.doPost({
          url:'/projectTeach/queryPackageDetail',
          data: {packageId: package_id},
          notice: '获取教学包内容失败'  
        })
        // try{
        //   res = await this.$http.request('/projectTeach/queryPackageDetail', {packageId: package_id});
        // }catch(err){
        //   // console.log(err);
        // }
      }
      
      if(res.contents && res.contents.ppackage){
        this.$set(this, 'package_data', res.contents.ppackage);
      }
      
    },
    async getTaskList(){
      const {package_id} = this;
      let res;
      if(package_id){
        res = await this.$global.doPost({
          url: '/projectTeach/queryPackageTaskList',
          data: {packageId: package_id},
          notice: '教学包任务列表查询失败'
        })
      }
      if(res.contents && res.contents.taskList){
        this.$set(this, 'task_list', res.contents.taskList);
      }
    },
    /**
     * @description: 初始化
     * @param {*}
     * @return {*}
     */
    async init(){
      this.getTeachingPackageData();
      this.getTaskList();
    },
    openTask(item){
      // this.$api.task.openShareTask({taskId: item.id})
      // console.log(item);
      this.$api.moduleTask.openTask({taskId: item.id, config: {
        view: true,
        share: true,
        // view: true,
        // teach: true,
      }})
    },
    doBuy(){
    },
    /**
     * @description: 免费领取 
     * @param {*}
     * @return {*}
     */
    async doBuyForFree(){
      const {package_id} = this;
      let data = {
        type: 2,
        id: package_id,
        price: 0,
      }
      let res = await this.$global.doPost({
        url: '/projectTeach/buy',
        data,
        notice: '领取失败'
      })
      if(res.message == 'success'){
        this.$notice({desc: '领取成功', type:'success'});
        this.$set(this.package_data, 'buy', true);
      }
    },

    async handleMenuClick(){
      this.setIndex();
    },
    setIndex(){
      this.$utils.setPopoverZIndex('.teaching_package_page_menu_poppver');
    },
    touchConsulting(){
      this.switch_consulting = true;
    },
    closeConsulting(){
      this.switch_consulting = false;
    },
    doShare(){
      this.$api.share.doStartShare({data: this.package_data, type: 2});

    }
  },
  mounted(){
    this.init();
  }
}
</script>

<style lang="scss" scoped>
$container_padding_x: 40px;
.teaching_package_page{
  width: 80%;
  height: 90%;
  max-width: 1400px;
  max-height: 1000px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.sidebar_group{
  border-left: 2px solid #F5F8FA;
  flex-shrink: 0;
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  height: 50px;
  .menu{
    @include background(20px, 5px);
    width: 30px;
    height: 30px;
    background-image: url(~@/assets/images/package/menu.png);
  }
  .close_group{
    @include background(16px,16px);
    // width: 60px;
    // height: 60px;
    width: 30px;
    height: 30px;
    border-top-right-radius: 4px;
    background: {
      // color: #f4f4f4;
      image: url(~@/assets/images/dialog/anc_fun_close.png);
    }
    margin: 0 10px;
  }
}
.container{
  @include flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  flex: 1;
  height: 100%;
  position: relative;
  overflow-y: auto;
}
.buy_group{
  position: absolute;
  top: 0;
  right: 0;
}
.header_group{
  @include bbox;
  padding: 30px $container_padding_x;
  flex-shrink: 0;
  border-bottom: 2px solid #f4f4f4;
  .title_group{
    font-size: 18px;
    color: #0054A7;
    padding-bottom: 5px;
  }
  .info_wrapper{
    flex: 1;
    @include scrollbar;
    overflow-y: auto;
    max-height: 330px;
  }
  .info_group{
 
    @include flex;
    @include bbox;
    align-items: flex-start;
   
   
  }
  .descript_group{
    @include flex;
    @include bbox;
    flex-direction: column;
    align-items: stretch;
    padding-right: 30px;
    width: 100%;
    flex: 1;
    flex-shrink: 0;
    .title{
      font-size: 16px;
      color: #333333;
      padding: 10px 0;
    }
    .descript{
      font-size: 14px;
      color: #666666;
      line-height: 24px;
      padding-top: 5px;
      word-break: break-all;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
  .purchase_group{
    padding: 0 30px;
    border-left: 2px solid #f4f4f4;
    // min-height: 200px;
    // justify-content: center;
    height: 330px;
    .title{
      font-size: 18px;
      font-weight: bold;
      color: #111111;
      line-height: 24px;
    }
    .price_group{
      color: #F3580C;
      font-weight: bold;
      padding-top: 10px;
      .symbol{
        font-size: 20px;
        padding-bottom: 4px;
      }
      .price{
        font-size: 36px;
      }
    }
    .hint_group{
      padding-top: 10px;
      font-size: 16px;
      color: #999999;
      text-decoration: underline;
      line-height: 21px;
      .mark{
        color: #F3580C;
      }
    }
    .button{
      width: 200px;
      line-height: 48px;
      border-radius: 4px;
      font-size: 16px;
      color: #FFFFFF;
    }
    .fun_group{
      padding-top: 20px;
      .button{
        margin: 0 5px;
      }
      .buy_now{
        width: 150px;
        text-align: center;
      }
      .consulting{
        // width: 80px;
        width: 200px;
        background-color: #2BB06C;
        .text{
          font-size: 16px;
          color: #FFFFFF;
          line-height: 48px;;
          padding-left: 5px;
        }
      }
     
    }
    .maker{
      width: 240px;
      line-height: 48px;
      text-align: center;
      background-color: #ccc;
      border-radius: 4px;
      color: #fff;
      margin-top: 20px;
    }
  }
  .free{
    margin-top: 20px;
  }
}
.picture_group{
  padding-top: 5px;
  .picture{
    width: 375px;
  }
}
.task_list_group{
  @include flex;
  @include bbox;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  height: 100%;
  padding: 30px $container_padding_x;
  overflow-y: auto;
  .title{
    flex-shrink: 0;
    font-size: 16px;
    color: #333333;
    padding-bottom: 20px;
  }
}
.list_group{
  @include scrollbar;
  @include flex;
  flex:1;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
  .unit{
    @include flex;
    @include bbox;
    flex-direction: column;
    align-items: stretch;
    margin: {
      right: 20px;
      bottom: 20px;
    }
    width: 160px;
    height: 140px;
    background-color: #fff;
    border: 1px solid #E8EEFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    .name_group{
      @include flex;
      @include bbox;
      align-items: flex-start;
      flex: 1;
      height: 100%;
      padding: 10px 10px 0;
      border-bottom: 1px solid #e8eeff;
    }
    .name{
      @include textjustify;
      @include multi_line_intercept(4);
      font-size: 12px;
      color: #333333;
      line-height: 18px;
    }
    .price_group{
      @include flex;
      justify-content: flex-end;
      flex-shrink: 0;
      height: 40px;
      padding: 0 10px;
      .price{
        font-size: 14px;
        color: #333333;
      }
    }
  }
}

</style>

<style lang="scss">
.teaching_package_page_menu_poppver{
  padding: 5px 0;
  margin-top: 0px!important;
  width: 80px;
  min-width: 80px;
  .menu_list{
    padding: 0 10px;
    .item{
      line-height: 30px;
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>
