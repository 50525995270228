<script>
export default {
  props: {
    packageList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  methods: {
    changeCurrentPackage (currentPackage) {
      this.$emit(
        'changeCurrentPackage',
        currentPackage
      )
    }
  }
}
</script>

<template>
<div
  class="package_list d-flex align-center flex-wrap"
>
  <template
    v-for="(item, index) in packageList"
  >
    <div
      :key="index"
      class="package d-flex align-center flex-column"
      @click.stop="changeCurrentPackage(item)"
    >
      <div
        class="title flex-shrink-0"
      >
        {{ item.title }}
      </div>
      <div
        class="pic_group"
      >
        <div
          v-if="!item.webpic"
          class="text d-flex align-center justify-center"
        >
          {{ item.title }}
        </div>
        <div
          v-else
          :style="{
            backgroundImage: `url(${ $file_url }${ item.webpic })`
          }"
          class="pic"
        >
        </div>
      </div>
    </div>
  </template>
</div>
</template>

<style scoped lang="scss">
.package_list {
  .package {
    width: 280px;
    height: 211px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E8EEFF;
    margin: {
      right: 10px;
      bottom: 10px;
    }
    .title {
      @include single_line_intercept;
      width: 100%;
      padding: 10px;
    }
    .pic_group {
      width: 100%;
      height: 100%;
      padding: 0 10px 10px;
      .text {
        @include single_line_intercept;
        width: 100%;
        height: 100%;
        background-color: #ccc;
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        padding: 10px;
        border-radius: 4px;
      }
      .pic {
        @include background;
        background-size: contain;
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
}
</style>
