<!--
 * @Author       : Hugo
 * @Date         : 2021-04-26 16:28:38
 * @LastEditTime : 2021-05-11 12:13:58
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectTasks.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="select_task_page"
  >
    <div class="header_group">
      选择任务
    </div>
    <div class="container flex">
      <div class="menu_group">
        <div class="menu">
          <div
            class="item cp"
            :class="{on: item.value == menu_value}"
            v-for="(item, index) in menu"
            :key="index"
            @click="changeMenu(item.value);"
          >{{item.name}}</div>
        </div>
      </div>
      <div class="main_group flex">
        <div class="search_group">
          <div class="search_box flex">
            <div class="icon"></div>
            <input
              type="text"
              class="search"
              placeholder="输入内容，Enter进行搜索"
              v-model="search_keyword"
              @keydown.enter="doSearch();"
            >
          </div>
        </div>
        <div class="task_group">
          <div class="task_list flex"
            id="task_list"
            ref="task_list"
          >
            <template
              v-for="(item, index) in task_list"
            >
              <task
                class="task"
                :data="item"
                :key="index"
                :open_selected="true"
                :selected="setTaskSelected(item)"
                type="preview"
              ></task>
            </template>
            <div class="task loading_group"
              v-show="loading"
            >
              <loading-icon></loading-icon>
            </div>
          </div>
          <div class="no_more" v-show="no_more">没有更多了</div>
        </div>
        <div class="btn_group flex">
          <button class="button btn_dark"
            v-preventReClick="2000"
            @click="doSure();"
          >确定</button>
          <button class="button btn_light"
            @click="doClose();"
          >取消</button>

        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import Task from '@/components/Common/Task.vue';
import LoadingIcon from '@/components/basic/loadingIcon.vue';
export default {
  components: {
    Task,
    LoadingIcon,
  },
  data(){
    return{
      menu_value: 1,
      search_keyword: '',
      menu: [
        {name: '我创建的', value: '1'},
        {name: '我接收的', value: '2'},
        {name: '我购买的', value: '4'},
        {name: '我销售的', value: '3'},
      ],
      // task_list: [],

      loading: false,
      no_more:false,

      loadmore_critical_value: false,

      task_type: 'create',

      folderId: this.getFolderId(),
      create_list: [],
      receive_list: [],
      purchase_list: [],
      sell_list: [],
      create_no_more: false,
      receive_no_more: false,
      purchase_no_more: false,
      sell_no_more: false,
    }
  },
  computed: {
    task_list(){
      const {task_type} = this;
      const task_list = this[`${task_type}_list`];
      return task_list;
    }
  },
  watch: {
    search_keyword: function(val){
      if(val == ''){
        this.getData();
      }
    },
    loadmore_critical_value: function(val){
      const {loading} = this;
      if(val && !loading){
        // console.log('加载触发');
        this.addToData();
      }
    },

  },  
  methods:{
    /**
     * @description: 预览任务
     * @param {*} data
     * @return {*}
     */
    taskClickHandler(data){
      this.$api.task.previewTask({taskId: data.id});
    },
    /**
     * @description: 关闭
     * @param {*}
     * @return {*}
     */
    doClose(){
      this.$store.commit('set_switch_select_tasks', false);
    },
    doClear(){
      const {commit} = this.$store;
      commit('set_custom_to_add_tasks', []);
      commit('set_custom_to_delete_tasks', []);
    },
    doSearch(){
      this.getData();
    },
    /**
     * @description: 获取当前文件夹id
     * @param {*}
     * @return {*}
     */
    getFolderId(){
      const {folderid} = this.$route.params;
      if(folderid){
        return this.$global.decodeBase64(folderid);
      }
      return 0;
    },
    /**
     * @description: 获取数据 [判断是否有更多]
     * @param {*} data
     * @return {*}
     */
    async getTaskList(data){
      const {task_type} = this;
      this.loading = true;
      const res = await this.$global.doPost({
        url: '/projectTask/taskByparamList',
        data,
        notice: '任务数据加载失败',
      })
      // 判断数据是否有更多数据
      if(res.contents && res.contents.taskList){
        if(res.contents.taskList.length < 20){
          this[`${task_type}_no_more`] = true;
        }
      }else{
        this[`${task_type}_no_more`] = true;
      }
      this.loading = false;
      return res;
    },
    /**
     * @description: 初次加载/切换数据 [加载两次]
     * @param {*}
     * @return {*}
     */
    async getData(){
      // this.$set(this, 'task_list', []);
      const {task_type} = this;
      // this.no_more = false;
      const {menu_value, search_keyword, folderId} = this;
      const data = {
        searchtype: menu_value,
        keyword: search_keyword,
        folderId,
      }
      const res = await this.getTaskList(data);
      if(res.contents && res.contents.taskList){
        this.$set(this, `${task_type}_list`, res.contents.taskList);
      }
      this.addEventListener();
      if(!this[`${task_type}_no_more`]){
        await this.addToData();
      }
    },
    /**
     * @description: 追加
     * @param {*}
     * @return {*}
     */
    async addToData(){
      const {task_list, menu_value, search_keyword, folderId, task_type} = this;
      const startId = task_list[task_list.length - 1].id;
      const data = {
        searchtype: menu_value,
        keyword: search_keyword,
        startId,
        folderId,
      }
      const res  = await this.getTaskList(data);
      if(res.contents && res.contents.taskList){
        let new_task_list = task_list.concat(res.contents.taskList);
        // this.$set(this, 'task_list', new_task_list);
        this.$set(this, `${task_type}_list`, new_task_list);
      }
    },
    changeMenu(value){
      this.menu_value = value;
      this.$refs.task_list.scrollTop = 0;
      if(value == 1){
        this.task_type = 'create';
      }
      if(value == 2){
        this.task_type = 'receive';
      }
      if(value == 3){
        this.task_type = 'sell';
      }
      if(value == 4){
        this.task_type = 'purchase';
      }
      if(this[`${this.task_type}_list`].length == 0){
        this.getData();
      }
    },
    async init(){
      this.doClear();
      this.getData();
    },
    /**
    * 滚动监听函数
    */
    scrollEventListenerHandler(){
      // // console.log('scrollEventListenerHandler');
      this.scrollTop = this.$refs.task_list.scrollTop;
      let status = false;
      if(this.$refs.task_list){
        const {clientHeight, scrollHeight} = this.$refs.task_list;
        const {scrollTop} = this;
        // // console.log(scrollHeight - scrollTop - clientHeight)
        if((scrollHeight - scrollTop - clientHeight) < 200){
          status = true;
        }
      }
      this.loadmore_critical_value = status;
    },

    addEventListener(){
      // // console.log('addEventListener');
      this.$nextTick(function(){
        this.$refs.task_list.scrollTop = 0;
        this.$refs.task_list.removeEventListener('scroll', this.scrollEventListenerHandler)
        //  200ms后开启监听
        setTimeout(() => {
          if(this.$refs.task_list){
            this.$refs.task_list.addEventListener('scroll', this.scrollEventListenerHandler, false);
          }
        }, 200);
        
      })
    },
    /**
     * @description: 设置选择状态
     * @param {*} item
     * @return {*}
     */
    setTaskSelected(item){
      if(item.custom_infolderflag != undefined){
        return item.custom_infolderflag
      }else{
        return item.infolderflag;
      }
    },
    /**
     * @description: 
     * @param {*}
     * @return {*}
     */
    doTaskSelected(item){
      const {infolderflag, custom_infolderflag } = item;
      let flag = false;
      if(custom_infolderflag == undefined){
        flag = !infolderflag;
      }else{
        flag = !custom_infolderflag;
      }
      this.$set(item, 'custom_infolderflag', flag);
      if(infolderflag){
        // 初始已经添加
        if(flag){
          // 被选中[从删除中移除]
          this.cancelToDelete(item);
        }else{
          // 被取消[添加到待删除]
          this.pushToDelete(item)
        }

      }else{
        // 初始未被添加
        if(flag){
          // 被选中[添加到待添加]
          this.pushToAdd(item);
        }else{
          // 被取消[从待添加中删除]
          this.cancelToAdd(item);
        }
        
      }
    },
    pushToAdd(item){
      const {custom_to_add_tasks} = this.$store.state;
      custom_to_add_tasks.push(item);
    },
    cancelToAdd(item){
      const {custom_to_add_tasks} = this.$store.state;
      let index = custom_to_add_tasks.findIndex(unit=>{
        return unit.id == item.id;
      })
      if(index != -1){
        custom_to_add_tasks.splice(index, 1);
      }
    },
    pushToDelete(item){
      const {custom_to_delete_tasks} = this.$store.state;
      custom_to_delete_tasks.push(item);
    },
    cancelToDelete(item){
      const {custom_to_delete_tasks} = this.$store.state;
       let index = custom_to_delete_tasks.findIndex(unit=>{
        return unit.id == item.id;
      })
      if(index != -1){
        custom_to_delete_tasks.splice(index, 1);
      }
    },
    async doSure(){
      // const {state, commit } = this.$store;
      let res = await this.updateFolderTasks();
      if(res.message == 'success'){
        this.$api.custom.updateCustomFilesAfterAdd();
      }
      this.doClose();
    },
    async updateFolderTasks(){
      const {state, commit } = this.$store;
      const {custom_to_add_tasks, custom_to_delete_tasks} = state;
      // console.log(custom_to_add_tasks, custom_to_delete_tasks);
      const {folderId} = this;
      let addIds = '';
      let deleteIds = '';
      if(custom_to_add_tasks.length){
        custom_to_add_tasks.forEach(item=>{
          addIds += addIds? `,${item.id}` : item.id ;
        })
      }
      if(custom_to_delete_tasks.length){
        custom_to_delete_tasks.forEach(item=>{
          deleteIds += deleteIds? `,${item.id}` : item.id ;
        })
      }
      if(addIds == '' && deleteIds == ''){
        return true;
      }
      let data = {
        folderId,
        addIds,
        deleteIds,
      }
      let res = await this.$global.doPost({
        url: '/projectCustom/addORdeleteFolderTask',
        data,
        notice: '任务操作失败',
      })
      return res;

    },
  },
  async mounted(){
    this.init();
    this.addEventListener();
  }
}
</script>

<style lang="scss" scoped>
.select_task_page{
  @include flex;
  flex-direction: column;
  align-items: stretch;
  width: 80%;
  height: 90%;
  max-width: 2000px;
  max-height: 1000px;
  background: #fff;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  .header_group{
    text-align: center;
    flex-shrink: 0;
    line-height: 76px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    border-bottom: 2px solid $border_color;
  }
  .container{
    height: 100%;
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
  .menu_group{
    @include bbox;
    padding-top: 20px;
    border-right: 2px solid $border_color;
    flex-shrink: 0;
    height: 100%;
  }
  .menu{
    .item{
      @include bbox;
      @include transition;
      position: relative;
      z-index: 2;
      width: 140px;
      font-size: 14px;
      line-height: 30px;
      padding-left: 30px;
      border-left: 6px solid transparent;
      color: #333;
      margin-bottom: 10px;
      // &::before{
      //   content: '';
      //   display: block;
      //   height: 100%;
      //   width: 6px;
      //   z-index: 3;
      //   position: absolute;
      //   left: -1px;
      //   top:0;
      //   background-color: transparent;
      // }
      &.on{
        color: $main_blue;
        border-left-color: $main_blue;
        // &::before{
          // background-color: $main_blue;
        // }
      }
    }
  }
  .main_group{
    flex-direction: column;
    background-color: #F5F8FA;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    .search_group{
      flex-shrink: 0;
    }
    .btn_group{
      flex-shrink: 0;
    }
  }
  .task_group{
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    .no_more{
      padding: 10px 0;
      text-align: center;
      width: 100%;
      font-size: 14px;
      color: #999;
      text-align: center;
    }
  }
  .task_list{
    @include bbox;
    flex-wrap: wrap;
    height: 100%;
    overflow-y: auto;
    padding: {
      left: 20px;
      top: 10px;
      // bottom: 10px;
    }
    .task{
      margin: {
        right: 20px;
        bottom: 20px;
      }
    }
    .loading_group{
      @include flex;
      justify-content: center;
      width: 160px;
      height: 140px;
      .loading{
        width: 40px;
        height: 40px;
      }
    }
  }
  .btn_group{
    @include bbox;
    padding: {
      right: 30px;
      top: 20px;
      bottom: 20px;
    }
    width: 100%;
    justify-content: flex-end;
    .button{
      border-radius: 4px;
      font-size: 16px;
      width: 155px;
      margin-left: 15px;
      line-height: 48px;
    }
  }
  .search_group{
    @include bbox;
    padding: 20px;
    width: 100%;
  }
  .search_box{
    @include bbox;
    width: 400px;
    padding: 0 20px;
    background-color:#fff;
    height: 40px;
    border-radius: 4px;
    .icon{
      @include background( 14px, 14px);
      background-image: url(~@/assets/images/common/search_icon.png);
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
    .search{
      @include bbox;
      @include placeholder(#999);
      outline: none;
      border: none;
      background-color: transparent;
      color: #333;
      font-size: 14px;
      padding: 0 10px;
      width: 100%;
      flex: 1;

    }
  }
}

</style>