var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select_task_page" }, [
    _c("div", { staticClass: "header_group" }, [_vm._v("\n    选择任务\n  ")]),
    _c("div", { staticClass: "container flex" }, [
      _c("div", { staticClass: "menu_group" }, [
        _c(
          "div",
          { staticClass: "menu" },
          _vm._l(_vm.menu, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item cp",
                class: { on: item.value == _vm.menu_value },
                on: {
                  click: function ($event) {
                    return _vm.changeMenu(item.value)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "main_group flex" }, [
        _c("div", { staticClass: "search_group" }, [
          _c("div", { staticClass: "search_box flex" }, [
            _c("div", { staticClass: "icon" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search_keyword,
                  expression: "search_keyword",
                },
              ],
              staticClass: "search",
              attrs: { type: "text", placeholder: "输入内容，Enter进行搜索" },
              domProps: { value: _vm.search_keyword },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.doSearch()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.search_keyword = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "task_group" }, [
          _c(
            "div",
            {
              ref: "task_list",
              staticClass: "task_list flex",
              attrs: { id: "task_list" },
            },
            [
              _vm._l(_vm.task_list, function (item, index) {
                return [
                  _c("task", {
                    key: index,
                    staticClass: "task",
                    attrs: {
                      data: item,
                      open_selected: true,
                      selected: _vm.setTaskSelected(item),
                      type: "preview",
                    },
                  }),
                ]
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "task loading_group",
                },
                [_c("loading-icon")],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.no_more,
                  expression: "no_more",
                },
              ],
              staticClass: "no_more",
            },
            [_vm._v("没有更多了")]
          ),
        ]),
        _c("div", { staticClass: "btn_group flex" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "preventReClick",
                  rawName: "v-preventReClick",
                  value: 2000,
                  expression: "2000",
                },
              ],
              staticClass: "button btn_dark",
              on: {
                click: function ($event) {
                  return _vm.doSure()
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "button",
            {
              staticClass: "button btn_light",
              on: {
                click: function ($event) {
                  return _vm.doClose()
                },
              },
            },
            [_vm._v("取消")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }