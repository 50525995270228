<!--
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-25 13:26:19
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-13 12:15:21
 * @FilePath     : /miaohang/src/components/Multitask/FunBar/Wrapper.vue
 * @Description  : 
-->
<template>
  <div class="c_multitask_fun_bar_index"
    v-if="ctask_list && ctask_list.length"
  >
    <div class="wrapper_group d-flex flex-column ul_sel bbox">

      <div
        class="icon clear cp"
        @click.stop="clearAll()"
      ></div>
      <template
        v-for="(item,index) in ctask_list"
      >
        <unit-component
          :key="index"
          :large_task="item"
          @click.stop.native="recoverTask(item, index)"
          @handleDelete="handleDelete"
        ></unit-component>
        <!-- <div
          class="task_group d-flex flex-column bbox cp"
          :key="index"
          @click.stop="recoverTask(item, index)"
        >
          <div class="name un_sel">{{item.otask.task.name || '未命名任务'}}</div>
          <div class="icon close"></div>
        </div> -->
      </template>
     
    </div>
  </div>
</template>

<script>
import UnitComponent from './Modules/Unit.vue'
import store from '@/store'
import old_task from '@/api/modules/task'
export default {
  components: {
    UnitComponent,
  },
  computed:{
    ctask_list(){
      return store.state.ctask_list
    }
  },
  methods: {
    clearAll() {
      this.$openConfirm({
        title: `是否关闭所有任务?`,
        desc: '关闭后，已编辑的内容无法恢复',
        onSure: () => {
          // this.ctask_list = []
          store.commit('set_ctask_list', [])
        }
      })
    },
    recoverTask(item,index) {
      old_task.recoverTask(item)
      this.ctask_list.splice(index, 1)
    },
    handleDelete(large_task){
      console.log(large_task)
      this.$openConfirm({
        title: `是否关闭任务${large_task.otask.task.name}?`,
        desc: '关闭后，已编辑的内容无法恢复',
        onSure: () => {
          const {ctask_list} = this
          const {id} = large_task
          if(id){
            const index = ctask_list.findIndex(unit=>{
              return unit.id === id
            })
            if(index !== -1){
              ctask_list.splice(index, 1)
            }
          }
        }
      })

    }
  }
}
</script>


<style lang="scss" scoped>
.c_multitask_fun_bar_index{
  @include hidescrollbar;
  overflow: auto;
  .wrapper_group{
    width: 30px;
    background-color: #eee;
    border-bottom: 1px solid #d0d0d0;
    position: relative;
    .clear{
      @include background(15px, 16px);
      height: 50px;
      width: 100%;
      background-image: url(~@/assets/images/multitask/fun_bar/clear.png);
      z-index: 3;
      border-top: 1px solid #d0d0d0;
      border-bottom: 1px solid #d0d0d0;
      background-color: #e0e0e0;
      position: sticky;
      top: 0px;
      left: 0;
    }
    // .task_group{
    //   @include transition;
    //   border-bottom: 1px solid #d0d0d0;
    //   width: 100%;
    //   padding: 10px 0 0;
    //   position: relative;
    //   z-index: 2;
    //   max-height: 200px;
    //   &.active, &:hover{
    //     background-color: #e0e0e0;
    //   }
    //   .name{
    //     @include single_line_intercept;
    //     writing-mode: vertical-rl;
    //     color: #555;
    //     font-family: 'MicroSoft YaHei';
    //   }
    //   .close{
    //     @include background(8px, 8px);
    //     width: 100%;
    //     height: 30px;
    //     background-image: url(~@/assets/images/multitask/fun_bar/close.png);

    //   }
    // }
  }
}
</style>