<!--
 * @Author       : Hugo
 * @Date         : 2021-01-18 15:02:56
 * @LastEditTime : 2021-08-18 11:07:19
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PersonalCenter/ActivationConfirm.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div class="activation_confirm_page">
    <div class="list_group">
      <div class="confirm_table_wrapper">
        <div class="confirm_table">
          <div class="row">
            <div class="key">产品类别</div>
            <div class="val">产品详情</div>
          </div>
          <div
            class="row"
            v-if="acti_confirm_data.batch"
          >
            <div class="key_rowspan">会员权益</div>
            <div class="val_list">
              <div class="list_unit">vip会员{{acti_confirm_data.batch.vipdays}}天</div>
            </div>
          </div>
          <div class="row"
            v-if="acti_confirm_data.tasklist"
          >
            <div class="key_rowspan">模板</div>
            <div class="val_list">
              <div
                class="list_unit"
                v-for="(item,index) in acti_confirm_data.tasklist"
                :key="index"  
              >{{item.name}}</div>
            </div>
          </div>
          <div class="row"
            v-if="acti_confirm_data.packagelist"
          >
            <div class="key_rowspan">教学包</div>
            <div class="val_list">
              <div
                class="list_unit"
                v-for="(item, index) in acti_confirm_data.packagelist"
                :key="index"  
              >{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fun_group">
      <button class="btn button sure"
        @click="doSureConfirm();"
      >确认激活</button>
      <button class="btn button cancel" @click="closeActiConfirmPage">取消激活</button>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    acti_confirm_data(){
      return this.$store.state.acti_confirm_data;
    },
  },
  methods: {
    /**
     * @description: 关闭确认框
     * @param {*}
     * @return {*}
     */
    closeActiConfirmPage(){
      this.$store.commit('set_switch_acti_confirm', false);
    },
    /**
     * @description: 确认激活
     * @param {*}
     * @return {*}
     */
    async doSureConfirm(){
      let {acti_code } = this.$store.state;
      if(acti_code){
        let res ;
        try{
          res = await this.$http.request('/project/bindUserCode', {usercode: acti_code});
          if(res.message == 'success'){
            this.$notice({desc: '绑定成功', type:'success'});
            this.closeActiConfirmPage();
            this.$store.commit('set_acti_status', 'done'); // 通知记录页面刷新列表
          }
        }catch(err){
          // console.log(err);
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.activation_confirm_page{
  @include flex;
  @include bbox;
  flex-direction: column;
  align-items: stretch;
  max-width: 80%;
  max-height: 80%;
  width: 100%;
  // height: 100%;
  background-color: #fff;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 40px 0;
  padding-bottom: 16px;
  // overflow: hidden;

  .list_group{
    @include scrollbar;
    @include bbox;
    padding:0 40px 10px;
    flex: 1;
    height: 100%;
    // overflow: hidden;
    overflow-y: auto;
    .confirm_table_wrapper{
      @include bbox;
      border: 2px solid #E5EEF6;
      width: 100%;
      overflow-y: auto;
      height: 100%;
    }
    .confirm_table{
      // .list_unit:nth-child(odd){
      //   background-color: #E5EEF6;
      // }
      .list_unit:hover{
        @include transition;
        background-color: #e5eef6;
      }
      .row {
        @include flex;
        width: 100%;
        font-size: 18px;
        text-align: center;
        // border-bottom: 1px solid #efeef6;
        align-items: stretch;
        // &:last-child{
        //   border-bottom: none;
        // }
        .key{
          width: 200px;
          border-right: 1px solid #efeef6;
          flex-shrink: 0;
          font-weight: bold;
          line-height: 60px;
          border-bottom: 1px solid #efeef6;
        }
        .val{
          text-align: center;
          width: 100%;
          flex:1;
          font-weight: bold;
          line-height: 60px;
          border-bottom: 1px solid #efeef6;
        }
        .key_rowspan{
          @include flex;
          justify-content: center;
          font-size: 16px;
          font-weight: bold;
          width: 200px;
          border-right: 1px solid #efeef6;
          border-bottom: 1px solid #efeef6;
        }
        .val_list{
          width: 100%;
          flex: 1;
          color: #333;
          font-size: 16px;
          line-height: 50px;
          width:100%;
        }
        .list_unit{
          @include bbox;
          padding: 0 40px;
          text-align: justify;
          text-align-last: left;
          border-bottom: 1px solid #efeef6;
        }
        
      }
    }
  }
  .fun_group{
    @include flex;
    @include bbox;
    justify-content: flex-end;
    flex-shrink: 0;
    padding: 16px 40px 0;
    border-top: 2px solid #f4f4f4;
    width: 100%;
    .btn{
      cursor: pointer;
      color: $main_blue;
      line-height: 48px;
      background-color: #e5eef6;
      padding: 0 45px;
      margin-left: 20px;
      font-size: 16px;
      border-radius: 4px;
      &.sure{
        background-color: $main_blue;
        color: #fff;
      }
    }
  }
}
</style>