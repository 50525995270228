var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_container_page d-flex flex-column .slide-out-blurred-right",
      style: {
        "box-shadow": _vm.box_shadow,
        border: _vm.border,
      },
    },
    [
      !_vm.sell
        ? _c("fun-bar", {
            staticClass: "fun_bar flex-shrink-0",
            attrs: {
              task: _vm.task,
              editable: _vm.editable,
              view: _vm.view,
              vip: _vm.vip,
              system: _vm.system,
              recycle: _vm.recycle,
            },
            on: {
              handleCall: _vm.callTask,
              handleUse: _vm.useTask,
              handleSend: _vm.handleSend,
              handleRecover: _vm.handleRecover,
              handleCompleteDelete: _vm.handleCompleteDelete,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex main_group align-stretch" },
        [
          _c("Main", {
            ref: "main",
            staticClass: "main",
            attrs: {
              select_target: _vm.select_target,
              editable: _vm.editable,
              view: _vm.view,
              system: _vm.system,
              recycle: _vm.recycle,
              sell: _vm.sell,
              shopping_mail: _vm.shopping_mail,
            },
            on: {
              handleCall: _vm.callTask,
              handleSortFile: _vm.handleSortFile,
            },
          }),
          _c("side-bar", {
            staticClass: "flex-shrink-0 side_bar",
            attrs: {
              type: _vm.sidebar_type,
              editable: _vm.editable,
              sell: _vm.sell,
              system: _vm.system,
              recycle: _vm.recycle,
              show_intro: _vm.show_intro,
              shopping_mail: _vm.shopping_mail,
            },
            on: {
              handleChangePannel: _vm.changePannel,
              handleAddNewWidget: _vm.addNewWidget,
              handleSwitchSidebar: _vm.switchSidebar,
              handleSavaTask: _vm.handleSavaTask,
            },
          }),
        ],
        1
      ),
      _vm.switch_file_sorting
        ? _c("file-sorting", {
            attrs: {
              list: _vm.file_sorting_list,
              widget: _vm.file_sorting_widget,
              parent: _vm.file_sorting_parent,
            },
            on: {
              handleClose: _vm.closeFileSorting,
              handleSure: _vm.sureFileSorting,
            },
          })
        : _vm._e(),
      _vm.switch_at
        ? _c("at-pop-up", {
            attrs: {
              chat_type: Number(_vm.chat_type),
              chat_id: Number(_vm.chat_id),
              task_id: Number(_vm.task_id),
              title: "从任务参与人中选择@对象11",
            },
            on: {
              handleClose: _vm.closeAt,
              handleSure: _vm.addAt,
              handleAll: _vm.atAll,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }