var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c_rl_course_package_edit_catalogue_section",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.touchSection.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "title_wrapper d-flex align-center justify-space-between",
        },
        [
          _c(
            "div",
            {
              staticClass: "title_group d-flex align-center",
              class: {
                active: _vm.active,
              },
            },
            [
              _c("div", { staticClass: "icon handler" }),
              _c("div", { staticClass: "title_box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.title,
                      expression: "title",
                    },
                  ],
                  staticClass: "title",
                  attrs: { type: "text", placeholder: "待输入章节标题" },
                  domProps: { value: _vm.title },
                  on: {
                    blur: _vm.titleBlur,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.title = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "title_calc" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.title ?? "待输入章节标题") +
                      "\n        "
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "add cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.touchAddTask.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        ＋\n      ")]
              ),
            ]
          ),
          _c("div", {
            staticClass: "del cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.delSection.apply(null, arguments)
              },
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "task_list" },
        [
          _vm.courseSection.tasks
            ? _c(
                "draggable",
                {
                  staticClass: "draggable_wrapper",
                  attrs: {
                    list: _vm.courseSection.tasks,
                    group: { name: `teach_task`, pull: true, put: true },
                    animation: "300",
                  },
                  on: { change: _vm.changeLog },
                },
                [
                  _vm._l(_vm.courseSection.tasks, function (item, index) {
                    return [
                      _c("TeachingTask", {
                        key: index,
                        staticClass: "teaching_task",
                        class: {
                          active: _vm.active,
                        },
                        attrs: { "task-info": item },
                        on: { touchDelect: _vm.touchDelect },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }