<!--
 * @Author       : Hugo
 * @Date         : 2020-05-22 14:14:22
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-11-20 23:10:18
 * @Description  : 申请好友外层容器
 * @FilePath     : \miaohang\src\components\Contact\ApplyBox.vue
--> 
<template>
  <div class="contact_apply_box">
    <div class="title_group">
      <div class="title">添加联系人</div>
      <img src="@/assets/images/common/close.png" alt="" @click.stop="closeAddFriend" class="close cp" >
    </div>
    <div class="search_group">
      <div class="search_box">
        <img src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp">
        <el-input
          v-model="input"
          placeholder="通过电话号码搜索平台用户"
          class="search"
          @keyup.enter.native="doSearch"
          ref="input"
          @input="inputChange"
          maxlength="11"
          @paste.native="pastePhone($event)"
        ></el-input>
        <img src="@/assets/images/common/clear_icon.png" alt="" class="clear_icon cp" v-show="input" @click.stop="input = ''">
      </div>
    </div>
    <div class="container_group">
      <div class="container_box">
        <ContactApplyBoxContent :status="status" :result="result"></ContactApplyBoxContent>
      </div>
      
    </div>
  </div>
</template>
<script>
import ContactApplyBoxContent from '@/components/Contact/ApplyBoxContent.vue';
export default {
  components:{
    ContactApplyBoxContent,
  },
  data(){
    return {
      input:'',
      status: 'default',
      result: null,
    }
  },
  mounted(){
  },
  watch: {
    input: function(val){
      //只能输入数字
      if(val){
        val = val.replace(/[^\d]+/g, "")
        this.input= val;
      }
      
    },
  },
  methods:{
    /**
     * 搜索用户
     */
    async doSearch(){
      if(this.input.length != 11){
        // this.$alert('请输入正确的手机号', '提示')
        this.$refs.input.blur(); // 移除焦点
        this.status = 'none';
        return; 
      }
      let data = {
        param: this.input,
      }
      let res = await this.$http.request('/project/searchUser',data);
      if(res.message == 'nodata'){
        this.status = 'none';
        return;
      }
      
      if(res.contents && res.contents.list && res.contents.list.length != 0){
        this.status = 'result';
        this.result = res.contents.list[0];
        return;
      }else{
        this.status = 'none';
      }
      
    },
    /**
     * 关闭弹窗
     */
    closeAddFriend(){
      this.input='';
      this.status = 'default';
      this.result = null;
      // this.$store.commit('close_add_friend_box');
      this.$api.common_close_add_friend_box()
    },
    /**
     * 输入为空时清空搜索结果
     */
    inputChange(){
      if(this.input == ''){
        this.result = null;
      }
    },
    /**
     * @description: 复制电话号时移除空格
     * @param {*} e
     * @return {*}
     */
    pastePhone(e){
      var data = null;
      var clipboardData = e.clipboardData; // IE
      if (!clipboardData) {
          //chrome
          clipboardData = e.originalEvent.clipboardData;
      }
      data = clipboardData.getData("Text");
      // console.log(data);
      data = data.replace(/\s*/g,"");
      this.input = data;
    }
  }
}
</script>
<style lang="scss" scoped>
.contact_apply_box{
  @include bbox;
  @include flex;
  flex-direction: column;
  width:602px;
  // height:768px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius:4px;
  .title_group{
    @include bbox;
    flex-shrink: 0;
    text-align: center;
    width:100%;
    position: relative;
    z-index: 2;
    padding-top:36px;
    .title{
      font-size:18px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      position: relative;
      z-index: 2;
    }
    .close{
      position: absolute;
      z-index: 3;
      top: 42px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
  }
  .search_group{
    flex-shrink: 0;
    padding: {
      top:39px;
      bottom: 30px;
    }
    .search_box{
      @include flexsb;
      @include bbox;
      padding: {
        left: 30px;
        right: 20px;
      }
      margin:0 auto;
      width:400px;
      height:40px;
      background:rgba(243,243,243,1);
      border-radius:20px;
      .search_icon{}
      .search{
        border:none;
        background-color: transparent;
        .el-input__inner{
          border:none;
          background-color: transparent;
        }
      }
      .clear_icon{}
    }
  }
  .container_group{
    @include bbox;
    @include flex;
    align-items: stretch;
    justify-content: center;
    height:100%;
    flex:1;
    width:100%;
    .container_box{
      width:400px;
    }
  }
}
</style>

<style lang="css">
  .contact_apply_box .search .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;
    
    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>