var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create_new_package_page" },
    [
      _c("div", { staticClass: "header_group" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.page_title))]),
        _c("div", {
          staticClass: "close cp",
          on: {
            click: function ($event) {
              return _vm.doClose()
            },
          },
        }),
      ]),
      _c("div", { staticClass: "content_group" }, [
        _c("div", { staticClass: "info_group" }, [
          _c("div", { staticClass: "info_box" }, [
            _vm.package_data
              ? _c("div", { staticClass: "line" }, [
                  _c("div", { staticClass: "key" }, [_vm._v("标题:")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.package_data.title,
                        expression: "package_data.title",
                      },
                    ],
                    staticClass: "val title",
                    attrs: { type: "text" },
                    domProps: { value: _vm.package_data.title },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.package_data,
                            "title",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.$api.package.doModified()
                        },
                      ],
                    },
                  }),
                ])
              : _vm._e(),
            _c("div", { staticClass: "line" }, [
              _c("div", { staticClass: "key" }, [_vm._v("简介:")]),
              _c("div", { staticClass: "val" }, [_c("div", { ref: "editor" })]),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("div", { staticClass: "key" }, [_vm._v("绑定账户数量：")]),
              _c("div", { staticClass: "val price_group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.accountnum,
                      expression: "accountnum",
                    },
                  ],
                  staticClass: "account_num bbox",
                  attrs: { type: "text" },
                  domProps: { value: _vm.accountnum },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.accountnum = $event.target.value
                      },
                      function ($event) {
                        return _vm.$api.package.doModified()
                      },
                    ],
                  },
                }),
                _c("div", { staticClass: "text" }, [_vm._v("个")]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "line", style: { "align-items": "center" } },
              [
                _c("div", { staticClass: "key" }, [_vm._v("课程总价格：")]),
                _c("div", { staticClass: "val price_group" }, [
                  _c("div", { staticClass: "text flex-shrink-0" }, [
                    _vm._v(_vm._s(_vm.price) + " 元"),
                  ]),
                  _c("div", { staticClass: "hint" }, [
                    _vm._v(
                      "根据签约讲师与官方利益分成比例8:2原则，讲师可分得利益为80%，约xxx元"
                    ),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "double_line" }, [
              _c("div", { staticClass: "unit" }, [
                _vm._m(0),
                _c(
                  "div",
                  {
                    ref: "img_group",
                    staticClass: "img_group",
                    class: {
                      active:
                        _vm.webimg && (_vm.webimg.preview || _vm.webimg.url),
                    },
                    style: { height: `${_vm.calculate_height}px` },
                  },
                  [
                    _c("input", {
                      ref: "webImg",
                      staticStyle: { display: "none" },
                      attrs: { type: "file", accept: "image/*" },
                      on: {
                        change: function ($event) {
                          return _vm.changeImg("web")
                        },
                      },
                    }),
                    _vm.webimg && (_vm.webimg.preview || _vm.webimg.url)
                      ? _c("img", {
                          staticClass: "show",
                          attrs: {
                            src:
                              _vm.webimg.preview ||
                              `${_vm.$img_url}${_vm.webimg.url}`,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "btn_group cp",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.webImg.click()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "icon" }),
                        _c("div", { staticClass: "btn" }, [
                          _vm._v(
                            _vm._s(
                              _vm.webimg &&
                                (_vm.webimg.preview || _vm.webimg.url)
                                ? "点击更换图片"
                                : "点击上传图片"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              0
                ? _c("div", { staticClass: "unit" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      {
                        ref: "img_group",
                        staticClass: "img_group",
                        class: {
                          active:
                            _vm.appimg &&
                            (_vm.appimg.preview || _vm.appimg.url),
                        },
                        style: { height: `${_vm.calculate_height}px` },
                      },
                      [
                        _c("input", {
                          ref: "appImg",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "image/*" },
                          on: {
                            change: function ($event) {
                              return _vm.changeImg("app")
                            },
                          },
                        }),
                        _vm.appimg && (_vm.appimg.preview || _vm.appimg.url)
                          ? _c("img", {
                              staticClass: "show",
                              attrs: {
                                src:
                                  _vm.appimg.preview ||
                                  `${_vm.$img_url}${_vm.appimg.url}`,
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "btn_group cp",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.appImg.click()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "icon" }),
                            _c("div", { staticClass: "btn" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.appimg &&
                                    (_vm.appimg.preview || _vm.appimg.url)
                                    ? "点击更换图片"
                                    : "点击上传图片"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "template_group" }, [
          _c("div", { staticClass: "add_group" }, [
            _c(
              "div",
              {
                staticClass: "add_box cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchAddTask.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "icon" }),
                _c("div", { staticClass: "text" }, [_vm._v("增加模板")]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: 0,
                    expression: "0",
                  },
                ],
                staticClass: "price_group",
              },
              [_vm._v("模板价值:200元")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "list_group" },
            [
              0
                ? [
                    _vm._l(
                      _vm.package_task_select_list,
                      function (item, index) {
                        return [
                          item.inFlag
                            ? _c("div", { key: index, staticClass: "list" }, [
                                _c("div", { staticClass: "info" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _c("div", {
                                  staticClass: "close cp",
                                  on: {
                                    click: function ($event) {
                                      item.inFlag = false
                                      _vm.$api.package.doModified()
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._l(_vm.taskList, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "list flex-column align-start d-flex",
                    },
                    [
                      _c("div", { staticClass: "info" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.name) +
                            "\n            "
                        ),
                      ]),
                      _c("div", { staticClass: "detail_group d-flex mt-6" }, [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: `${_vm.$avatar_url}${
                              item.createuserthumbnail ||
                              item.updateuserthumbnail
                            }@!small200`,
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", {
                        staticClass: "close cp",
                        on: {
                          click: function ($event) {
                            return _vm.deleteTaskList({ pTask: item })
                          },
                        },
                      }),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm.package_data
        ? _c("div", { staticClass: "fun_group" }, [
            _c(
              "div",
              {
                staticClass: "btn send",
                class: {
                  on: _vm.package_data.title,
                  cp: _vm.package_data.title,
                },
                on: { click: _vm.doSend },
              },
              [_vm._v("提交审核")]
            ),
            _vm.status_share_btn
              ? _c(
                  "button",
                  {
                    directives: [
                      { name: "preventReClick", rawName: "v-preventReClick" },
                    ],
                    staticClass: "btn btn_light cp button",
                    on: {
                      click: function ($event) {
                        return _vm.doShare()
                      },
                    },
                  },
                  [_vm._v("分享课程")]
                )
              : _vm._e(),
            0
              ? _c("div", { staticClass: "btn save" }, [_vm._v("保存草稿")])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.switch_add_template,
                expression: "switch_add_template",
              },
            ],
            staticClass: "mask_floor",
          },
          [
            _c(
              "div",
              { staticClass: "add_template_wrapper" },
              [
                _c("create-new-package-add-template", {
                  attrs: { switch_add_template: _vm.switch_add_template },
                  on: {
                    "update:switch_add_template": function ($event) {
                      _vm.switch_add_template = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("展示图：")]),
      _c("div", { staticClass: "hint" }, [_vm._v("建议尺寸16:9")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("APP端展示图:")]),
      _c("div", { staticClass: "hint" }, [_vm._v("建议尺寸16:9")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }