<!--
 * @Author       : Hugo
 * @Date         : 2020-05-22 14:26:21
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-09-18 14:54:55
 * @Description  : 申请好友内容容器
 * @FilePath     : \miaohang\src\components\Contact\ApplyBoxContent.vue
--> 
<template>
  <div class="contact_apply_box_content">
    <div class="wrapper">
      <div class="default_group group" v-show="status == 'default'">
        <img src="@/assets/images/common/default.png" alt="" class="default">
      </div>
      <div class="group none_group" v-show="status == 'none'">
        <img src="@/assets/images/common/none.png" alt="" class="none">
        <div class="hint">暂无搜索结果</div>
      </div>
      <div class="group result_group" v-if="status == 'result' && result != null" >
        <div class="result_box">
          <div class="info_group">
            <img :src="`${$avatar_url}${result.thumbnail}`" alt="" class="avatar">
            <div class="name_group">
              <div class="name">{{result.userName}}</div>
              <div class="desc">{{result.phone}}</div>
            </div>
          </div>
          <div class="fun_group" v-show="can_apply">
            <div class="fun cp" @click.stop="goToAddFriend">申请</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    status:{
      type:String,
      default:'default',
    },
    result: {
      type: Object,
      default: null,
    }
  },
  data(){
    return {
      can_apply: true,
    }
  },
  watch:{
    status: function(newVal){
      this.status = newVal;
    },
    result: {
      handler: function(val){
        this.$set(this, 'result', val);
        this.isFriendOrSelf();
      },
      deep: true,
    },
    friends_list: {
      handler: function(){},
      deep: true,
    },
  },
  computed:{
    friends_list(){
      // console.log(this.$store.state.friends_list,'this.$store.state.friends_list')
      return this.$store.state.friends_list;
    },
    
  },
  mounted(){
    this.isFriendOrSelf();
  },
  methods:{
    async goToAddFriend(){
      this.$store.commit('to_add_friend', this.result);
    },
    isFriendOrSelf(){
      if(this.friends_list && this.result){
        let userId = this.$tools.getUserId();
        // // console.log(userId, this.result, this.friends_list)
        if(this.friends_list){
          if(this.result.userId == userId){
            this.can_apply = false;
          }else{
            // 好友列表
            for(let i in this.friends_list){

              if(this.friends_list[i].friendUserId == this.result.userId){
                this.can_apply = false;
                return;
              }
            }
            this.can_apply = true;
          }
        }else{
          this.can_apply = true;
        }
      }else{
        this.can_apply = true;
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.contact_apply_box_content{
  width:100%;
  height:100%;
  .wrapper{
    @include bbox;
    @include flex;
    justify-content: center;
    width:100%;
    height:100%;
    min-height: 60+120+30px;
    .group{
      @include flex;
      justify-content: center;
      width:100%;
      height: 100%;

    }
    .default_group{
      .default{}
    }
    .none_group{
      flex-direction: column;
      padding: 30px 0;
      .hint{
        padding-top:20px;
        font-size:14px;
        
        font-weight:400;
        color:rgba(153,153,153,1);
      }
      .none{}
    }
    .result_group{
      @include bbox;
      @include flex;
      justify-content: center;
      width:100%;
      height: 100%;
      
      .result_box{
        @include flex;
        width: 572px;
        .info_group{
          @include flex;
          @include bbox;
          align-items: stretch;
          width: 100%;
          flex: 1;
          .avatar{
            flex-shrink: 0;
            width:100px;
            height:100px;
            border-radius:4px;
            background-color:#f5f6f7;
          }
          .name_group{
            @include flexsb;
            @include bbox;
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            width:100%;
            flex: 1;
            .name{
              font-size:18px;
              
              font-weight:400;
              color:rgba(51,51,51,1);
            }
            .desc{
              font-size:16px;
              
              font-weight:400;
              color:rgba(153,153,153,1);
            }
          }
        }
        .fun_group{
          flex-shrink: 0;
          .fun{
            @include transition;
            width:100px;
            line-height:40px;
            background-color: $main_blue;
            border-radius:4px;
            font-size:16px;
            
            font-weight:400;
            color:rgba(255,255,255,1);
            text-align: center;
            &:hover{
              background-color: #004080;
            }
          }
        }
      }
    }

  }
}
</style>