var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "package_list d-flex align-center flex-wrap" },
    [
      _vm._l(_vm.packageList, function (item, index) {
        return [
          _c(
            "div",
            {
              key: index,
              staticClass: "package d-flex align-center flex-column",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.changeCurrentPackage(item)
                },
              },
            },
            [
              _c("div", { staticClass: "title flex-shrink-0" }, [
                _vm._v("\n        " + _vm._s(item.title) + "\n      "),
              ]),
              _c("div", { staticClass: "pic_group" }, [
                !item.webpic
                  ? _c(
                      "div",
                      {
                        staticClass: "text d-flex align-center justify-center",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.title) + "\n        "
                        ),
                      ]
                    )
                  : _c("div", {
                      staticClass: "pic",
                      style: {
                        backgroundImage: `url(${_vm.$file_url}${item.webpic})`,
                      },
                    }),
              ]),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }