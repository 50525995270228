var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "fail_reason bbox_wrapper d-flex flex-column align-center",
    },
    [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.reason,
            expression: "reason",
          },
        ],
        staticClass: "reason",
        attrs: { placeholder: "请给出未通过原因" },
        domProps: { value: _vm.reason },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.reason = $event.target.value
          },
        },
      }),
      _c(
        "div",
        { staticClass: "fun_group d-flex align-center justify-space-between" },
        [
          _c(
            "div",
            {
              staticClass: "btn btn_dark",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doSure.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      确认\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "btn btn_light",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doClose.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      取消\n    ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }