var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "course_catalogue d-flex align-center flex-column bbox_wrapper",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "fixed_group flex-shrink-0 justify-start align-start flex-column",
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "fun_group d-flex align-center" }, [
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchAddSection.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "icon section" }),
                _c("div", { staticClass: "name" }, [
                  _vm._v("\n          增加章节\n        "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchAddTask.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "icon task" }),
                _c("div", { staticClass: "name" }, [
                  _vm._v("\n          增加模板\n        "),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "catalogue_wrapper" }, [
        _c(
          "div",
          { staticClass: "catalogue_group" },
          [
            _vm.list
              ? _c(
                  "draggable",
                  {
                    ref: "catalogue_group",
                    staticClass: "draggable_wrapper",
                    attrs: {
                      list: _vm.list,
                      group: {
                        name: `course_section`,
                        pull: false,
                        put: false,
                      },
                      handle: ".c_rl_course_package_edit_catalogue_section",
                      animation: "300",
                    },
                    on: { change: _vm.changeLog },
                  },
                  [
                    _vm._l(_vm.list, function (item) {
                      return [
                        _c("CatalogueSection", {
                          key: item.addNumber,
                          staticClass: "catalogue_section",
                          attrs: { "course-section": item },
                          on: { delSection: _vm.delSection },
                        }),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name_group d-flex align-center" }, [
      _c("div", { staticClass: "symbol" }, [_vm._v("\n        *\n      ")]),
      _c("div", { staticClass: "name" }, [_vm._v("\n        目录\n      ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }