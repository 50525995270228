var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_rl_course_package_edit_main_wrapper d-flex" },
    [
      _c("CourseInfo", {
        ref: "info",
        staticClass: "info_group",
        on: { automaticDetection: _vm.automaticDetection },
      }),
      _c("CourseCatalogue", {
        staticClass: "catalogue_group flex-shrink-0",
        on: { automaticDetection: _vm.automaticDetection },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }