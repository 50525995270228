var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common_wrapper" },
    [
      _c("div", {
        staticClass: "close cp",
        attrs: { src: "~@/assets/images/common/close.png", alt: "" },
        on: {
          click: function ($event) {
            return _vm.doClose()
          },
        },
      }),
      _vm.popup_common_type == "remove_chat_user"
        ? _c("remove-chat-user", { ref: "remove_chat_user" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }