<!--
 * @Author       : Hugo
 * @Date         : 2020-09-08 11:51:21
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-04-22 11:53:44
 * @Description  : 创建教学包(添加模板)
 * @FilePath     : /miaohang/src/components/FunTemplate/CreateNewPackageAddTemplate.vue
-->
<template>
  <div class="create_new_package_add_template">
    <div class="content_group">
      <div class="content_box"
        ref="scrollBox"
      >
        <div class="item" v-for="(item, index) in task_list" :key="index">
          <div class="info_group">
            <div class="info">{{item.name}}</div>
          </div>
          <div class="fun_group">
            <div
              class="select_box cp"
              :class="{on: item.inFlag}"
              @click="clickTask(item)"
            ></div>
            <div class="status">{{item.checkstatus | checkstatus}}</div>
          </div>
        </div>
        <div class="item loading"
          v-if="!nomore && loading"
        >
          <loading-icon class="loading_icon" size="30px"></loading-icon>
        </div>
        <div
          class="nomore" 
          v-if="nomore"
        >没有更多了</div>
      </div>
    </div>
    <div class="fun_group">
      <div
        class="btn sure"
        :class="{on: canSure}"
        @click="doSure"
      >确认</div>
      <div class="btn cancel" @click="switchAddTemplate(false)">取消</div>
    </div>
  </div>
</template>
<script>
import LoadingIcon from '@/components/basic/loadingIcon.vue';
export default {
  components: {
    LoadingIcon,
  },
  props:{
    switch_add_template:{
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      task_list: null,
      canSure: false,
      nomore: false,
      scrollTop: 0,
      loading: false,
      loadmore_critical_value: false,
      modified: false,
    }
  },
  computed:{
    package_data(){
      return this.$store.state.package_data;
    },
    package_task_select_list(){
      let data = this.$store.state.package_task_select_list;
      return data;
    },
    package_task_list(){
      let data = this.$tools.deepClone(this.$store.state.package_task_list);
      return data;
    },
    // /**
    //  * 加载更多临界值
    //  */
    // loadmore_critical_value(){
    //   let status = false;
    //   // console.log(this.$refs.scrollBox);
    //   if(this.$refs.scrollBox){
    //     const {clientHeight, scrollHeight} = this.$refs.scrollBox;
    //     const {scrollTop} = this;
    //     // console.log(scrollHeight - scrollTop - clientHeight)
    //     if((scrollHeight - scrollTop - clientHeight) < 200){
    //       status = true;
    //     }
    //   }
    //   // console.log(status);
    //   return status;
    // }


  },
  watch: {
    package_data: {
      handler: function(){},
      deep: true,
    },
    package_task_select_list: {
      handler: function(val){
        this.setTaskList(val);
      },
      deep: true,
    },
    package_task_list: {
      handler: function(){},
      deep: true,
    },
    task_list: {
      handler: function(val){
        if(val){
          let canSure = false;
          val.forEach(item => {
            if(item.inFlag){
              canSure = true;
            }
          })
          this.canSure = canSure;
        }
      },
      deep: true,
    },
    loadmore_critical_value: {
      handler: function(val){
        const {loading, nomore} = this;
        if(val && !loading && !nomore){
          // console.log('触发加载更多');
          this.getMoreTaskList();
        }
      },
    },
    switch_add_template: function(val){
      if(val){
        this.modified = false;
      }
    }
  },
  filters: {
    checkstatus: function (val) {
      let text = '';
      if(val){
        switch(val){
          case 1:
            text = '未审核';
            break;
          case 2: 
            text = '审核中';
            break;
          case 3:
            text = '通过';
            break;
          case 4:
            text = '不通过';
            break;
          default: 
            text = '';
            break
        }
      }
      return text;
    }
  },
  methods:{
    // 关闭
    switchAddTemplate(swit){
      
      this.$emit('update:switch_add_template', swit);
    },
    setTaskList(list){
      let task_list = this.$tools.deepClone(list)
      this.$set(this, 'task_list', task_list);
    },
    async init(){
      // this.modified = false;
      await this.getTaskList();
      if(!this.nomore){
        await this.getMoreTaskList();
      }
      // this.setTaskList(this.package_task_select_list)
    },
    /**
     * 点击任务
     */
    clickTask(item){
      // 已选中
      if(item.inFlag){
        item.inFlag = false;
      }else{
        item.inFlag = true;
      }
      this.modified = true;
    },
    doSure(){
      // 是否修改
      if(this.modified){
        this.$api.package.doModified();
      }
      if(this.canSure){
        this.$store.commit('set_package_task_select_list', this.task_list);
        this.switchAddTemplate(false);
      }
      
    },
    /** 获取用于选择的 教学包模板列表 
     * package_task_select_list 用于选择
    */
    async getPackageTaskSelectList(startId){
      this.loading = true;
      const {package_data} = this.$store.state;
      let packageId = package_data.id || '';
      let res = await this.$global.doPost({
        url: '/projectTask/taskByparamList',
        data: {searchtype: 3,packageId, startId},
        notice: '获取任务单列表失败',
      })
      this.loading = false;
      return res;
    },
    async getTaskList(){
      let res = await this.getPackageTaskSelectList();
      let nomore = false;
      if(res.contents && res.contents.taskList && res.contents.taskList.length){
        let package_task_select_list = res.contents.taskList;
        this.$store.commit('set_package_task_select_list', package_task_select_list);
        this.setTaskList(package_task_select_list);
        if(package_task_select_list.length < 20){
          nomore = true;
        }
      }else{
        nomore = true;
      }
      this.nomore = nomore;
     
    },
    async getMoreTaskList(){
      const {package_task_select_list} = this.$store.state;
      const startId = package_task_select_list[package_task_select_list.length -1].id;
      let nomore = false;
      let res = await this.getPackageTaskSelectList(startId);
      if(res.contents && res.contents.taskList && res.contents.taskList.length){
        let list = res.contents.taskList;
        let new_list = [...package_task_select_list, ...list];
        this.$store.commit('set_package_task_select_list', new_list);
        this.setTaskList(new_list);

      }else{
        nomore = true;
      }
      this.nomore = nomore;
    },
    /**
    * 滚动监听函数
    */
    scrollEventListenerHandler(){
      this.scrollTop = this.$refs.scrollBox.scrollTop;
      let status = false;
      if(this.$refs.scrollBox){
        const {clientHeight, scrollHeight} = this.$refs.scrollBox;
        const {scrollTop} = this;
        // console.log(scrollHeight - scrollTop - clientHeight)
        if((scrollHeight - scrollTop - clientHeight) < 200){
          status = true;
        }
      }
      this.loadmore_critical_value = status;
    },
  },
  async mounted(){
    await this.init();

    this.$nextTick(function(){
      this.$refs.scrollBox.removeEventListener('scroll', this.scrollEventListenerHandler)
      // this.scrollToBottom();
      // this.$store.commit('set_task_trigger_scroll_to_bottom', false);
      //  200ms后开启监听
      setTimeout(() => {
        if(this.$refs.scrollBox){
          this.$refs.scrollBox.addEventListener('scroll', this.scrollEventListenerHandler, false);
        }
      }, 200);
      
    })
  }
}
</script>
<style lang="scss" scoped>
.create_new_package_add_template{
  @include flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .fun_group{
    @include flex;
    @include bbox;
    flex-shrink: 0;
    justify-content: flex-end;
    width: 100%;
    padding: 16px 40px;
    .btn{
      cursor: pointer;
      width: 155px;
      line-height: 48px;
      background: #ccc;
      border-radius: 4px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      margin-left: 20px;
      &.sure.on{
        background-color: $main_blue;
      }
      &.cancel{
        background-color: rgba(0, 84, 167, 0.1);
        color: $main_blue;
      }
    }
  }
  .content_group{
    @include bbox;
    height: 100%;
    width: 100%;
    flex: 1;
    overflow: hidden;
    padding: 40px 0;

    .content_box{
      @include scrollbar;
      @include flex;
      @include bbox;
      overflow-y: auto;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      height: 100%;
      padding: {
        left: 40px;
        right: 20px;
      }
    }
    .item{
      width: 160px;
      background: #F3F3F3;
      border: 1px solid #EDEDED;
      box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      margin: {
        right: 20px;
        bottom: 20px;
      }
      &.loading{
        @include flex;
        height: 140px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        justify-content: center;
        .loading_icon{
          width: 30px;
          height: 30px;
        }
      }
      .info_group{
        @include bbox;
        width: 100%;
        padding: 20px;
        background-color:#fff;
        .info{
          @include multi_line_intercept(4);
          @include text_justify;
          text-align-last: left;
          width: 100%;
          height: 18*4px;
          font-size: 12px;
          
          font-weight: 400;
          color: #333333;
          line-height: 18px;

        }
      }
      .fun_group{
        @include bbox;
        @include flexsb;
        padding: 0 20px;
        height: 35px;
        width: 100%;
        .select_box{
          @include background(18px, 18px);
          @include transition;
          width: 20px;
          height: 20px;
          background-image: url(~@/assets/images/common/square_select.png);
          &.on{
            background-image: url(~@/assets/images/common/square_selected.png);
          }

        }
        .status{
          font-size: 14px;
          color: #05AD00;
        }
      }
    }
    .nomore{
      @include flex;
      justify-content: center;
      width: 160px;
      height: 140px;
      font-size: 14px;
      color: #aaa;
    }
    
  }
}
</style>