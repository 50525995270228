<script>
import draggable from 'vuedraggable'
import TeachingTask from '@/components/resource_library/course_package/edit/catalogue/teaching_task'
import CourseSection from '@/models/cource_package/course_section'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  components: {
    draggable,
    TeachingTask,
  },
  props: {
    courseSection: {
      type: CourseSection,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      coursePackage: state => state.course_package.data,
      // courseId: state => state.course_package.id,
      coursePackageSection: state => state.course_package.section,
    }),
    active () {
      const { coursePackageSection, courseSection } = this
      if (!coursePackageSection) {
        return false
      }
      return coursePackageSection.addNumber === courseSection.addNumber
    },
    notEditable () {
      const { coursePackage } = this
      if (coursePackage && Number(coursePackage.checkstatus) === 2) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      title: '',
      addNumber: '',
      list: [
        {
          item: 1,
        },
        {
          item: 2,
        },
        {
          item: 1,
        },
        {
          item: 2,
        },
        {
          item: 1,
        },
        {
          item: 2,
        },
        {
          item: 1,
        },
        {
          item: 2,
        },

      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    changeLog (e) {
      // console.log(e)
      // console.log(this.coursePackage)
      // console.log(this.courseSection)
      // console.log(this.coursePackageSection)
    },
    init () {
      if (this.courseSection) {
        const {
          title,
          addNumber,
        } = this.courseSection
        this.title = title
        this.addNumber = addNumber
      }
    },
    touchSection () {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      store.commit(
        'setCoursePackageSection',
        this.courseSection
      )
    },
    delSection () {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      this.$emit('delSection', {
        section: this.courseSection
      })
    },
    touchDelect (taskInfo) {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      // console.log(taskInfo.id)
      const index = this.courseSection.tasks.findIndex((unit) => {
        // console.log(unit.id)
        return unit.id === taskInfo.id
      })
      if (index !== -1) {
        this.courseSection.tasks.splice(index, 1)
        store.commit('setCoursePackageCheckForUpdates', true)
      }

    },
    titleBlur () {
      this.courseSection.title = this.title
    },
    touchAddTask () {
      if (this.notEditable) {
        this.$notice({
          desc: '该课程正在审核中,无法编辑',
          type: 'error'
        })
        return
      }
      this.touchSection()
      this.$modal.show('globalCoursePackageChoiceTask')
    },
  }
}
</script>

<template>
<div
  class="c_rl_course_package_edit_catalogue_section"
  @click.stop="touchSection"
>
  <div
    class="title_wrapper d-flex align-center justify-space-between"
  >
    <div
      class="title_group d-flex align-center"
      :class="{
        active
      }"
    >
      <div
        class="icon handler"
      ></div>
      <div
        class="title_box"
      >
        <input
          v-model="title"
          type="text"
          class="title"
          placeholder="待输入章节标题"
          @blur="titleBlur"
        >
        <div
          class="title_calc"
        >
          {{ title ?? '待输入章节标题' }}
        </div>
      </div>
      <div
        class="add cp"
        @click.stop="touchAddTask"
      >
        ＋
      </div>

    </div>
    <div
      class="del cp"
      @click.stop="delSection"
    ></div>
  </div>
  <div
    class="task_list"
  >
    <draggable
      v-if="courseSection.tasks"
      class="draggable_wrapper"
      :list="courseSection.tasks"
      :group="{ name:`teach_task`, pull: true, put: true }"
      animation="300"
      @change="changeLog"
    >
      <template
        v-for="(item, index) in courseSection.tasks"
      >
        <TeachingTask
          :key="index"
          class="teaching_task"
          :class="{
            active
          }"
          :task-info="item"
          @touchDelect="touchDelect"
        ></TeachingTask>
      </template>
    </draggable>
  </div>
</div>
</template>

<style scoped lang="scss">
.c_rl_course_package_edit_catalogue_section {
  .title_wrapper {
    width: 100%;
    .title_group {
      .icon {
        @include background(14px 14px);
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url(~@/assets/images/course_package/add_section.png);
      }
      .title_box {
        position: relative;
        overflow: auto;
        margin-right: 10px;
        .title_calc {
          @include single_line_intercept;
          min-width: 100px;
          max-width: 210px;
          position: relative;
          z-index: 2;
          color: transparent;
          font-size: 14px;
          padding: 0;
          line-height: 24px;
          height: 24px;
        }
        .title {
          position: absolute;
          width: 100%;
          height: 24px;
          line-height: 24px;
          border: none;
          outline: none;
          z-index: 9;
          top: 0;
          left: 0;
          color: #333;
        }
      }
      .add {
        border-left: 2px solid #ccc;
        margin-right: 10px;
        padding-left: 10px;
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
      }

      &.active {
        .icon {
          background-image: url(~@/assets/images/course_package/add_section_on.png);
        }
        .title_box {
          .title {
            @include placeholder($main_blue);
            color: $main_blue;
          }
        }
        .add {
          //border-left-color: $main_blue;
          color: $main_blue;
        }
      }
    }
    .del {
      @include background(14px 16px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/course_package/del_section.png);
    }
  }
  .task_list {
    width: 100%;
    padding-right: 3px;
    .teaching_task {
      margin-top: 16px;
      background-color: #F4F4F4;
      border-radius: 4px;
      &.active {
        background-color: rgba(0, 84, 167, 0.1);
      }
    }
  }
}
</style>
