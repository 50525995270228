var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create_dialog_selected_unit" }, [
    _c("div", { staticClass: "name_group" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: {
          src: `${_vm.$avatar_url}${_vm.selectedData.userdata.thumbnail}@!small200`,
          alt: "",
        },
      }),
      _c("div", { staticClass: "name" }, [
        _vm._v(
          _vm._s(
            _vm.selectedData.userdata.friendRemark
              ? _vm.selectedData.userdata.friendRemark
              : _vm.selectedData.userdata.userName
          )
        ),
      ]),
    ]),
    !_vm.selectedData.userdata.isInChat || _vm.is_single_dialog
      ? _c("div", { staticClass: "select_box" }, [
          _c("div", { staticClass: "select cp", on: { click: _vm.del } }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }