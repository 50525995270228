<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      appUserInfo: state => state.app.userInfo,
    }),
    showMajor () {
      const {
        identity
      } = this
      return identity === '' || identity === 1 || identity === 2
    },
  },
  data () {
    return {
      loading: false,
      identityList: [
        {
          name: '教师',
          value: 1,
        },
        {
          name: '学生',
          value: 2,
        },
        {
          name: '企业用户',
          value: 3,
        },
        {
          name: '自由职业者',
          value: 4,
        },
        {
          name: '其他',
          value: 5,
        },
      ],
      identity: '',
      major: '',
      majorList: [
        {
          name: '会展策划与管理（540112）'
        },
        {
          name: '旅游管理（540101）'
        },
        {
          name: '酒店管理与数字化运营（540106）'
        },
        {
          name: '市场营销（530605）'
        },
        {
          name: '国际经济与贸易（530501）'
        },
        {
          name: '文化创意策划（550401）'
        },
        {
          name: '网络与新媒体（360101）'
        },
        {
          name: '视觉传达设计（550102）'
        },
        {
          name: '展示艺术设计（550110）'
        },
        {
          name: '环境艺术设计（550106）'
        },
        {
          name: '数字媒体艺术（550103）'
        },
        {
          name: '数字媒体技术（510204）'
        },
        {
          name: '其他'
        },
      ],
      userCardNo: '',
      department: '',
      userRealName: '',
      ownedEnterprises: '',
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const {
        appUserInfo
      } = this
      if (!appUserInfo) {
        return
      }
      const {
        identity,
        major,
        department,
        userRealName,
        ownedEnterprises,
        userCardNo,
      } = appUserInfo
      this.identity = identity
      this.major = major
      this.department = department
      this.userRealName = userRealName
      this.ownedEnterprises = ownedEnterprises
      this.userCardNo = userCardNo
    },
    /**
     * 校验身份证
     * @param id
     * @returns {boolean}
     */
    validateIDCard (id) {
      const idCardRegex = /^(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][0-9])|(3[0-1]))\d{3}[\dXx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(1[0-2]))(([0-2][0-9])|(3[0-1]))\d{3}$)$/
      return idCardRegex.test(id)
    },
    async doSubmit () {
      if (this.loading) {
        return
      }
      const {
        userCardNo,
        identity,
        major,
        department,
        userRealName,
        ownedEnterprises,
      } = this

      if (identity === '') {
        this.$notice({
          desc: '请选择身份',
          type: 'error'
        })
        return
      } else {
        if (identity === 1 || identity === 2) {
          if (!major) {
            this.$notice({
              desc: '请选择专业',
              type: 'error'
            })
            return
          }
        }
        //   else {
        //   if (department === null || department.trim() === '') {
        //     this.$notice({
        //       desc: '请输入部门信息',
        //       type: 'error'
        //     })
        //     return
        //   }
        // }

      }
      if (userRealName.trim() === '') {
        this.$notice({
          desc: '请输入真实姓名',
          type: 'error'
        })
        return
      }

      if (!this.validateIDCard(userCardNo)) {
        this.$notice({
          desc: '请输入正确格式的身份证号',
          type: 'error'
        })
        return
      }

      if (ownedEnterprises === null ||  ownedEnterprises.trim() === '') {
        this.$notice({
          desc: '请输入单位名称',
          type: 'error'
        })
        return
      }

      const data = {
        identity,
        userRealName,
        userCardNo,
        ownedEnterprises,
      }
      if (identity === 1 || identity === 2) {
        data.major = major
        data.department = ''
      } else {
        data.major = ''
        data.department = department
      }
      this.loading = true
      const res = await this.appUserInfo.realNameAuthentication(data)
      this.loading = false

      if (res) {
        this.$notice({
          desc: '认证成功',
          type: 'success'
        })
        this.$modal.hide('globalAdditionInformation')
      }


    }
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="v_login_addition_information bbox_wrapper"
>
  <div
    class="title_group"
  >
    实名认证
  </div>
  <div
    class="information_wrapper d-flex align-center justify-center"
  >
    <div
      class="avatar_group d-flex align-center flex-column justify-center"
    >
      <div
        class="avatar"
        :style="{
          backgroundImage: `url(${ $avatar_url }${ appUserInfo.thumbnail }@!small200)`
        }"
      ></div>
      <div
        class="username"
      >
        {{ appUserInfo.userName }}
      </div>
    </div>
    <div
      class="info_wrapper"
    >
      <!--身份-->
      <div
        class="info_group"
      >
        <div
          class="key"
        >
          <div
            class="symbol"
          >
            *
          </div>
          <div
            class="text"
          >
            身&nbsp;&nbsp;份:
          </div>
        </div>
        <div
          class="value"
        >
          <el-select
            v-model="identity"
            clearable
            placeholder="请选择身份"
            class="select_group views_active_page_laboratory_index_select_group"
            value="identities"
          >
            <el-option
              v-for="item in identityList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!--真实姓名-->
      <div
        class="info_group"
      >
        <div
          class="key"
        >
          <div
            class="symbol"
          >
            *
          </div>
          <div
            class="text"
          >
            真实姓名:
          </div>
        </div>
        <div
          class="value"
        >
          <input
            v-model="userRealName"
            type="text"
            class="text"
            placeholder="请输入真实姓名"
          >
        </div>
      </div>
      <!--身份证-->
      <div
        class="info_group"
      >
        <div
          class="key"
        >
          <div
            class="symbol"
          >
            *
          </div>
          <div
            class="text"
          >
            身份证号码:
          </div>
        </div>
        <div
          class="value"
        >
          <input
            v-model="userCardNo"
            type="text"
            class="text"
            placeholder="请输入身份证号码"
            maxlength="18"
          >
        </div>
      </div>
      <!--单位名称-->
      <div
        class="info_group"
      >
        <div
          class="key"
        >
          <div
            class="symbol"
          >
            *
          </div>
          <div
            class="text"
          >
            单位名称:
          </div>
        </div>
        <div
          class="value"
        >
          <input
            v-model="ownedEnterprises"
            type="text"
            class="text"
            placeholder="请输入单位名称"
          >
        </div>
      </div>
      <div
        class="info_group"
        v-show="showMajor"
      >
        <div
          class="key"
        >
          <div
            class="symbol"
          >
            *
          </div>
          <div
            class="text"
          >
            专&nbsp;&nbsp;业:
          </div>
        </div>
        <div
          class="value"
        >
          <el-select
            v-model="major"
            clearable
            placeholder="请选择专业"
            class="select_group views_active_page_laboratory_index_select_group"
            value="identities"
          >
            <el-option
              v-for="item in majorList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        v-show="!showMajor"
        class="info_group"
      >
        <div
          class="key"
        >
          <div
            class="symbol"
          >
            <!--*-->
          </div>
          <div
            class="text"
          >
            部&nbsp;&nbsp;门:
          </div>
        </div>
        <div
          class="value"
        >
          <input
            v-model="department"
            type="text"
            class="text"
            placeholder="请输入部门名称"
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="fun_group d-flex justify-center"
  >
    <div
      class="btn btn_dark cp"
      @click.stop="doSubmit"
    >
      完成
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_login_addition_information {
  width: 100%;
  .title_group {
    text-align: center;
    font-size: 18px;
    color: #333333;
    line-height: 70px;
    font-style: normal;
    border-bottom: 2px solid #F0F0F0;
  }
  .information_wrapper {
    width: 100%;
    padding: {
      top: 80px;
    }
    .avatar_group {
      flex: 1;
      .avatar {
        @include background;
        width: 92px;
        height: 92px;
      }
      .username {
        color: #333;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        padding-top: 40px;
      }
    }
    .info_wrapper {
      flex: 1;
      .info_group {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        padding-right: 100px;
        .key {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          padding-right: 20px;
          .symbol {
            font-weight: 400;
            font-size: 14px;
            color: #E12F2F;
            line-height: 12px;
            text-align: right;
            font-style: normal;
            margin-right: 5px;
            width: 5px;
          }
          .text {
            width: 94px;
            height: 21px;
            font-size: 16px;
            color: #0054A7;
            line-height: 21px;
            text-align: justify;
            text-align-last: justify;
            font-style: normal;
          }
        }
        .value {
          width: 100%;
          flex: 1;
          border-bottom: 1px solid #D5D9DB;
          .select_group {
            width: 100%;
            .el-input__inner {
            }
          }
          .text {
            @include placeholder(#ccc);
            line-height: 40px;
            width: 100%;
            padding-left: 15px;
            font-size: 14px;
            color: #333;
            outline: none;
          }
        }
      }
    }
  }
  .fun_group {
    width: 100%;
    padding: {
      top: 50px;
      bottom: 80px;
    }
    .btn {
      width: 415px;
      line-height: 50px;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      font-style: normal;
    }
  }
}
</style>
<style lang="scss">
.views_active_page_laboratory_index_select_group {
  .el-input__inner {
    @include placeholder(#ccc);
    border: none!important;
    color: #333;
  }
}
</style>
