var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_multitask_fun_bar_m_unit d-flex flex-column bbox cp" },
    [
      _c("div", { staticClass: "name un_sel" }, [_vm._v(_vm._s(_vm.name))]),
      _c("div", {
        staticClass: "icon close",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleDelete()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }