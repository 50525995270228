var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact_send_applycation" }, [
    _c("div", { staticClass: "info_group" }, [
      _c("div", { staticClass: "name_group" }, [
        _c("img", {
          staticClass: "avatar",
          attrs: { src: `${_vm.$avatar_url}${_vm.result.thumbnail}`, alt: "" },
        }),
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.result.userName)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "msg_group" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("告诉他（她）：")]),
          _c("el-input", {
            staticClass: "msg",
            attrs: {
              type: "textarea",
              placeholder: "我是...",
              resize: "none",
              maxlength: "300",
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c("div", { staticClass: "length_hint" }, [
            _vm._v("最大字符: " + _vm._s(_vm.length) + "/300"),
          ]),
          _c("div", { staticClass: "remark_title pb-16 align-self-start" }, [
            _vm._v("备注名:"),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.friendRemark,
                expression: "friendRemark",
              },
            ],
            staticClass: "remark bbox",
            attrs: { type: "text", placeholder: "点击为他/她设置备注名" },
            domProps: { value: _vm.friendRemark },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.friendRemark = $event.target.value
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "fun_group" }, [
      _c(
        "div",
        {
          staticClass: "fun cp cancel",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$api.common_close_add_friend_box.apply(null, arguments)
            },
          },
        },
        [_vm._v("取消")]
      ),
      _c(
        "button",
        {
          directives: [{ name: "preventReClick", rawName: "v-preventReClick" }],
          staticClass: "fun cp sure",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doApply.apply(null, arguments)
            },
          },
        },
        [_vm._v("申请")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }