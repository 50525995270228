var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "course_info bbox_wrapper" }, [
    _c("div", { staticClass: "line_group flex-shrink-0" }, [
      _vm._m(0),
      _c("div", { staticClass: "info_group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
          ],
          staticClass: "title",
          attrs: {
            type: "text",
            placeholder: "请填写标题",
            disabled: _vm.notEditable,
          },
          domProps: { value: _vm.title },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.title = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _c("div", { staticClass: "line_group" }, [
      _vm._m(1),
      _c("div", { staticClass: "info_group" }, [
        _c("div", { ref: "editor", staticClass: "editor_wang_course_info" }),
      ]),
    ]),
    _c("div", { staticClass: "line_group" }, [
      _vm._m(2),
      _c(
        "div",
        { staticClass: "info_group" },
        [
          _c(
            "el-select",
            {
              staticClass: "un_sel select_teach_type_group",
              attrs: {
                placeholder: "请选择教学类型",
                size: "small",
                disabled: _vm.notEditable,
              },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            },
            _vm._l(_vm.teachTypeList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "line_group" }, [
      _vm._m(3),
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "upload_group d-flex" }, [
          _c(
            "div",
            {
              staticClass: "btn_group d-flex",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchUpload("videofile")
                },
              },
            },
            [
              _c("div", { staticClass: "icon video" }),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.videofile ? "替换概述" : "上传概述") +
                    "\n          "
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "hide" }, [
            _vm._v("\n          *仅支持上传视频类文档\n        "),
          ]),
          _c("input", {
            ref: "videofile",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: "video/*" },
            on: { change: _vm.videoChange },
          }),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingvideofile || _vm.videofile,
                expression: "loadingvideofile || videofile",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingvideofile,
                expression: "loadingvideofile",
              },
            ],
            staticClass: "file_group",
          },
          [
            _c("div", { staticClass: "file_info d-flex" }, [
              _c("div", { staticClass: "icon video" }),
              _c("div", { staticClass: "filename" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.loadingvideofile ? "上传中" : _vm.videofile) +
                    "\n          "
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "line_group" }, [
      _vm._m(4),
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "upload_group d-flex" }, [
          _c(
            "div",
            {
              staticClass: "btn_group d-flex",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchUpload("docfile")
                },
              },
            },
            [
              _c("div", { staticClass: "icon file" }),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.docfile ? "替换标准" : "上传标准") +
                    "\n          "
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "hide" }, [
            _vm._v("\n          *仅支持上传文本类文档\n        "),
          ]),
          _c("input", {
            ref: "docfile",
            staticStyle: { display: "none" },
            attrs: {
              type: "file",
              accept:
                ".pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            },
            on: { change: _vm.docChange },
          }),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadingdocfile || _vm.docfile,
                expression: "loadingdocfile || docfile",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingdocfile,
                expression: "loadingdocfile",
              },
            ],
            staticClass: "file_group",
          },
          [
            _c("div", { staticClass: "file_info d-flex" }, [
              _c("div", {
                staticClass: "icon",
                class: {
                  [_vm.docType]: true,
                },
              }),
              _c("div", { staticClass: "filename" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.loadingdocfile ? "上传中" : _vm.videofile) +
                    "\n          "
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "line_group" }, [
      _vm._m(5),
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "upload_group d-flex align-start" }, [
          _c("div", { staticClass: "img_group un_sel cp" }, [
            !_vm.notEditable
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text_group d-flex align-center justify-center",
                    class: {
                      on: _vm.webpic,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchUpload("webpic")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n              + 点击" +
                          _vm._s(_vm.webpic ? "更换" : "上传") +
                          "图片\n            "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", {
              staticClass: "img",
              style: {
                backgroundImage: `url(${_vm.$file_url}${_vm.webpic})`,
              },
            }),
          ]),
          _c("div", { staticClass: "hide" }, [
            _vm._v("\n          *建议尺寸16:9\n        "),
          ]),
          _c("input", {
            ref: "webpic",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.picChange },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name_group" }, [
      _c("div", { staticClass: "symbol" }, [_vm._v("\n        *\n      ")]),
      _c("div", { staticClass: "name" }, [_vm._v("\n        标题:\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name_group" }, [
      _c("div", { staticClass: "symbol" }, [_vm._v("\n        *\n      ")]),
      _c("div", { staticClass: "name" }, [_vm._v("\n        简介:\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name_group" }, [
      _c("div", { staticClass: "symbol" }, [_vm._v("\n        *\n      ")]),
      _c("div", { staticClass: "name" }, [_vm._v("\n        类型:\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name_group" }, [
      _c("div", { staticClass: "symbol" }),
      _c("div", { staticClass: "name" }, [_vm._v("\n        概述:\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name_group" }, [
      _c("div", { staticClass: "symbol" }),
      _c("div", { staticClass: "name" }, [_vm._v("\n        标准:\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name_group" }, [
      _c("div", { staticClass: "symbol" }),
      _c("div", { staticClass: "name" }, [_vm._v("\n        展示图:\n      ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }