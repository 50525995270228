<!--
 * @Author       : Hugo
 * @Date         : 2020-08-18 14:54:50
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-07-22 10:04:50
 * @Description  : 
 * @FilePath     : /miaohang/src/components/AnnouncementControl/ContactSelectedContainer.vue
-->
<template>
  <div class="control_contact_selected_wrapper">
    <div class="title_group">
      <div class="title">选择联系人</div>
      <img src="@/assets/images/common/close.png" alt="" class="close cp" @click="$store.commit('set_switch_annc_selectbox', false)">
    </div>
    <div class="search_group">
      <div class="search_box">
        <img src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp">
        <el-input v-model="input" placeholder="搜索" class="search"></el-input>
        <img src="@/assets/images/common/clear_icon.png" alt="" class="clear_icon cp">
      </div>
    </div>
    <div class="container_group">
      <!-- fun_task_dialog_group -->
      <div class="select_group">
        <div class="top_title" v-if="$route.name == 'fun_task_dialog_group'">组内联系人</div>
        <template v-if="$route.name == 'fun_task_dialog_group'">
          <div class="unit padding" v-for="(item, index) in group_list" :key="`group${index}`" v-show="((input && item.searchName) || !input)">
            <div class="create_dialog_select_unit" :class="{'on': item.on}">
              <div class="name_group">
                <img :src="`${$avatar_url}${item.thumbnail}`" alt="" class="avatar">
                <div class="name" v-html="item.searchName ? item.searchName : item.friendRemark || item.userName"></div>
              </div>
              <div class="select_box">
                <div class="select cp" @click="doSelect(item,'group')"></div>
              </div>
            </div>
            <!-- <CreateDialogSelectUnit :userData="item" :on="item.on && true"   @select-this="selectThis(item)"  ></CreateDialogSelectUnit> -->
          </div>
        </template>

        <div class="top_title">全部联系人</div>
        <div class="unit padding" v-for="(item, index) in select_group" :key="`index${index}`" v-show="((input && item.searchName) || !input)">
          <div class="create_dialog_select_unit" :class="{'on': item.on}">
            <div class="name_group">
              <!-- <img :src="`${$avatar_url}${userData.thumbnail}`" alt="" class="avatar"> -->
              <img :src="`${$avatar_url}${item.thumbnail}`" alt="" class="avatar">
              <div class="name" v-html="item.searchName ? item.searchName : item.friendRemark || item.userName"></div>
            </div>
            <div class="select_box">
              <div class="select cp" @click="doSelect(item,'friend')"></div>
            </div>
          </div>
          <!-- <CreateDialogSelectUnit :userData="item" :on="item.on && true"   @select-this="selectThis(item)"  ></CreateDialogSelectUnit> -->
        </div>
      </div>
      <div class="selected_group">
        <div class="unit padding" v-for="(item, index) in selected_group" :key="index">
          <div class="create_dialog_selected_unit">
            <div class="name_group">
              <!-- <img :src="`${$avatar_url}${selectedData.thumbnail}`" alt="" class="avatar"> -->
              <img :src="`${$avatar_url}${item.thumbnail}`" alt="" class="avatar">
              <div class="name">{{item.friendRemark || item.userName}}</div>
            </div>
            <div class="select_box">
              <div class="select cp" @click="delSelected(item,index)"></div>
            </div>
          </div>
          <!-- <CreateDialogSelectedUnit @del-this="delThis(item)" :selectedData="item"></CreateDialogSelectedUnit> -->
        </div>
      </div>
    </div>
    <div class="fun_group">
      <div class="btn sure" @click="sure">确定</div>
      <div class="btn cancel" @click="$store.commit('set_switch_annc_selectbox', false)">取消</div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      input:'',
      select_group: this.friendsListHandler(),
      selected_group: [],
      group_list: null,
    }
  },
  computed: {
    /**
     * 会话用户列表
     */
    dialog_user_list(){
      return this.$store.state.dialog_user_list;
    },
    /**好友列表 */
    friends_list(){
      let friends_list = this.$tools.deepClone(this.$store.state.friends_list);
      if(friends_list){
        friends_list.forEach( item =>{
          item.userId = item.friendUserId;
        });
      }
      return friends_list;
    },
    /**已选择列表 */
    contact_selectbox_list(){
      return this.$store.state.control.contact_selectbox_list;
    },
    
  },
  watch: {
    dialog_user_list: {
      handler: function(){
        // this.generateSelectGroup();
      },
      deep: true,
    },
    friends_list: {
      handler: function(){
        this.$set(this,'select_group', this.friendsListHandler());
        // this.generateSelectGroup();
      },
      deep: true,
    },
    select_group: {
      handler:function(){},
      deep: true,
    },
    selected_group: {
      handler:function(){},
      deep: true,
    },
    contact_selectbox_list: {
      handler: function(){
        this.init();
      },
      deep: true,
    },
    /**搜索 联系人 */
    input: function(val){
      val = val.trim();
      let select_group = this.filterContact(val);
      this.$set(this, 'select_group', select_group);
    },
  },
  methods:{
    init(){
      let {select_group, group_list} = this;
      let contact_selectbox_list = this.contact_selectbox_list;
      // // console.log(contact_selectbox_list)
      // if(contact_selectbox_list && friends_list){
        this.$set(this, 'selected_group', contact_selectbox_list);
        if(select_group){
          select_group.forEach(item => {
            if(contact_selectbox_list){
              let isIn = contact_selectbox_list.find(unit=>{
                return unit.userId == item.userId;
              })
              if(isIn){
                item.on = true;
              }else{
                item.on = false;
              }
            }else{
              item.on = false;
            }
          })
        }
        if(group_list){
          group_list.forEach(item => {
            if(contact_selectbox_list){
              let isIn = contact_selectbox_list.find(unit=>{
                return unit.userId == item.userId;
              })
              if(isIn){
                item.on = true;
              }else{
                item.on = false;
              }
            }else{
              item.on = false;
            }
          })
        }
      // }
    },
    // 处理好友信息
    friendsListHandler(){
      let friends_list = this.$tools.deepClone(this.$store.state.friends_list);
      if(friends_list){
        friends_list.forEach( item =>{
          item.userId = item.friendUserId;
        });
      }
      return friends_list;
    },
    /**
     * @param {String} type 类型 'friend/group' 好友/群组
     */
    doSelect(item, type){
      // console.log(item)
      let selected_group = this.$tools.deepClone(this.selected_group);
      if(item.on){
        // 移除
        selected_group.forEach((unit, index) => {
          if(unit.userId == item.userId){
            selected_group.splice(index, 1);
          }
        })
      }else{
        // 添加
        selected_group.push(item);
      }
      this.$set(this, 'selected_group', selected_group)
      // console.log(selected_group)
      item.on = !item.on;
      if(type=='friend'){
        let unit = this.group_list.find(unit => {
          return unit.userId == item.userId;
        })
        if(unit){
          unit.on = !unit.on;
        }
      }else{
        let unit = this.select_group.find(unit => {
          return unit.userId == item.userId;
        })
        if(unit){
          unit.on = !unit.on;
        }
      }
    },
    /** 移除 */
    delSelected(item, index){
      let {select_group, group_list} = this;
      select_group.forEach( unit => {
        if(unit.userId == item.userId){
          unit.on = false;
        }
      })
      group_list.forEach( unit => {
        if(unit.userId == item.userId){
          unit.on = false;
        }
      })
      let selected_group = this.$tools.deepClone(this.selected_group);
      selected_group.splice(index, 1);
      this.$set(this, 'selected_group', selected_group);
    },
    /**
     * 确认
     */
    sure(){
      let selected_group = this.$tools.deepClone(this.selected_group);
      if(selected_group.length != 0){
        selected_group.forEach(item => {
          delete item.on;
        })
      }
      this.$store.commit('control_set_contact_selectbox_list', selected_group);
      this.$store.commit('set_annc_modified', true);
      // // console.log(this.$store.state.control.contact_selectbox_list)
      // // console.log(selected_group)
      // // console.log(selected_group);
      this.$store.commit('set_switch_annc_selectbox', false)
    },
     /**
     * @description: 筛选联系人
     * @param {String} val 搜索字段 
     * @return {Object} 选择列表 
     */
    filterContact(val){
      let select_group = this.select_group;
      // 循环用户 如果用户名或昵称匹配得上 新增字段 searchName
      if(val){
        select_group.forEach( (citem,index) => {
          if(citem.userName.toLowerCase().indexOf(val.toLowerCase()) != -1){
            citem.searchName = this.$tools.highlightKeywords(citem.userName, val);
          }else if(citem.friendRemark.toLowerCase().indexOf(val.toLowerCase()) != -1){
            citem.searchName = this.$tools.highlightKeywords(citem.friendRemark, val);
          }else{
            citem.searchName = '';
          }
          this.$set(this.select_group[index], 'searchName',  citem.searchName)
        })
      }else{
        select_group.forEach( (citem,index) => {
          citem.searchName = '';
          this.$set(this.select_group[index], 'searchName',  citem.searchName)
        })
      }
      return select_group;
    },
  },
  async mounted(){
    /**设置群组联系人 */
    if(this.$route.name == 'fun_task_dialog_group'){
      let {dialog_user_list} = this.$store.state;
      if(dialog_user_list){
        this.$set(this, 'group_list', dialog_user_list)
      }else{
        // get
        let {id} = this.$route.params;
        let res = await this.$http.request('/projectFriends/chatUserList', {chatId: id});
        this.$store.commit('set_dialog_user_list',res.content.list);
      }
    }
    // 如果好友不存在获取
    if(!this.friends_list){
      this.$store.dispatch('get_friends_list');
    }
    this.init();
    
  }
}
</script>
<style lang="scss" scoped>
.control_contact_selected_wrapper{
  @include bbox;
  @include flex;
  flex-direction: column;
  height: 80%;
  width: 602px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius:4px;
  overflow: hidden;
  .title_group{
    @include bbox;
    flex-shrink: 0;
    text-align: center;
    width:100%;
    position: relative;
    z-index: 2;
    padding-top:36px;
    .title{
      font-size:18px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      position: relative;
      z-index: 2;
    }
    .close{
      position: absolute;
      z-index: 3;
      top: 42px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
  }
  .search_group{
    flex-shrink: 0;
    padding: {
      top:39px;
      bottom: 30px;
    }
    .search_box{
      @include flexsb;
      @include bbox;
      padding: {
        left: 30px;
        right: 20px;
      }
      margin:0 auto;
      width:400px;
      height:40px;
      background:rgba(243,243,243,1);
      border-radius:20px;
      .search_icon{}
      .search{
        border:none;
        background-color: transparent;
        .el-input__inner{
          border:none;
          background-color: transparent;
        }
      }
      .clear_icon{}
    }
  }
  .container_group{
    @include bbox;
    @include flex;
    align-items: stretch;
    height:100%;
    max-height: 100%;
    flex:1;
    width:100%;
    overflow: hidden;
    border: {
      top: 2px solid #f0f0f0;
      bottom: 2px solid #f0f0f0;
    }
    .padding{
      padding: {
        top: 10px;
        bottom: 10px;
        left:37px;
        right:46px;
      }
    }
    .select_group{
      @include bbox;
      @include hidescrollbar;
      padding: 20px 0;
      overflow-y: auto;
      width:50%;
      border-right: 1px solid #f0f0f0;
      .add_new_group{
        @include flex;
        padding-top: 10px;
        .img{
          width: 44px;
          height: 44px;
          border-radius: 4px;
          display: block;
        }
        .text{
          padding-left: 16px;
          font-size:14px;
          
          font-weight:400;
          color:rgba(51,51,51,1);
        }
      }
      .top_title{
        color: #666;
        font-size: 14px;
        padding: {
          left:37px;
          right: 46px;
          top: 10px;
          bottom: 10px;
        }
      }
      .firstname_group{
        .title{
          @include bbox;
          font-size:14px;
          
          font-weight:400;
          color:rgba(153,153,153,1);
          text-transform: uppercase;
        }
        .unit{
          @include bbox;
          @include transition;
          background-color:#fff;
          &:hover{
            background-color: $background_hover_color;
          }
        }
      }
      .create_dialog_select_unit{
        @include bbox;
        @include flexsb;
        @include transition;
        // background-color: #fff;
        width:100%;
        overflow: hidden;
        padding: {
          // left: 37px;
          // right: 46px;
          // top: 11px;
          // bottom: 11px;
        }
        // &:hover{
        //   background-color:#f4f4f4;
        // }
        &.on{
          .select_box{
            .select{
              border:none;
              background-image: url(~@/assets/images/create_dialog/selected.png);
              background-size: 100%;
            }
          }
        }
        .name_group{
          @include flex;
          @include bbox;
          width:100%;
          flex: 1;
          padding-right: 15px;
          .avatar{
            width: 44px;
            height: 44px;
            border-radius: 4px;
            flex-shrink: 0;
          }
          .name{
            @include bbox;
            padding-left: 16px;
            width:100%;
            flex:1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size:14px;
            
            font-weight:400;
            color:rgba(51,51,51,1);
          }
        }
        .select_box{
          width:18px;
          height:18px;
          flex-shrink: 0;
          .select{
            @include bbox;
            // @include transition;
            width: 100%;
            height: 100%;
            border:2px solid rgba(153,153,153,1);
            border-radius:50%;
          }
        }
      }
      
    }
    .selected_group{
      @include bbox;
      @include hidescrollbar;
      flex-shrink: 0;
      padding: {
        top: 20px;
        bottom: 20px;
      }
      overflow-y: auto;
      width:50%;
      border-left: 1px solid #f0f0f0;
      .unit{
        @include bbox;
        @include transition;
        background-color:#fff;
        &:hover{
          background-color: $background_hover_color;
        }
        .create_dialog_selected_unit{
          @include bbox;
          @include flexsb;
          @include transition;
          // background-color: #fff;
          width:100%;
          overflow: hidden;
          // padding: {
          //   left: 37px;
          //   right: 46px;
          //   top: 11px;
          //   bottom: 11px;
          // }
          // &:hover{
          //   background-color:#f4f4f4;
          // }
          .name_group{
            @include flex;
            @include bbox;
            width:100%;
            flex: 1;
            overflow: hidden;
            padding-right: 15px;
            .avatar{
              width: 44px;
              height: 44px;
              border-radius: 4px;
              flex-shrink: 0;
            }
            .name{
              @include bbox;
              padding-left: 16px;
              width:100%;
              flex:1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size:14px;
              
              font-weight:400;
              color:rgba(51,51,51,1);
            }
          }
          .select_box{
            width:18px;
            height:18px;
            flex-shrink: 0;
            .select{
              @include bbox;
              @include transition;
              width: 100%;
              height: 100%;
              border-radius:50%;
              border:none;
              background-image: url(~@/assets/images/create_dialog/delete.png);
              background-size: 100%;
            }
          }
        }
      }
    }
  }
  .fun_group{
    @include flex;
    padding:20px 0;
    flex-shrink: 0;
    .btn{
      @include transition;
      width:160px;
      line-height:50px;
      background:rgba(204,204,204,1);
      border-radius:4px;
      margin: 0 20px;
      font-size:18px;
      
      font-weight:400;
      text-align: center;
      color:#fff;
      cursor: pointer;
    }
    .sure{
      background-color: $main_blue;
      &:hover{
        background-color: #004080;
      }
    }
    .cancel{
      background-color:rgba(0,84,166,0.1);
      color: $main_blue;
      &:hover{
        background-color: rgba(0,84,166,0.2);
      }
    }
  }
}
</style>

<style lang="css">
  .search .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;
    
    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>