var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "communication_record_msg_unit" }, [
    _vm._m(0),
    _c("div", { staticClass: "container_group" }, [
      _c(
        "div",
        {
          staticClass: "container_box",
          class: { template: _vm.type == "template" },
        },
        [
          _vm.type == "template"
            ? _c("img", {
                staticClass: "arrow",
                attrs: {
                  src: require("@/assets/images/dialog/template_arrow.png"),
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.type != "template"
            ? _c("img", {
                staticClass: "arrow",
                attrs: {
                  src: require("@/assets/images/dialog/receive_arrow.png"),
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.type != "file"
            ? _c("div", { staticClass: "text" }, [_vm._t("text")], 2)
            : _vm._e(),
          _vm.type == "file"
            ? _c("div", { staticClass: "filename_group" }, [
                _c("div", { staticClass: "filename" }, [_vm._t("filename")], 2),
                _c("div", { staticClass: "size" }, [_vm._v("11.6M")]),
              ])
            : _vm._e(),
          _vm.type == "file"
            ? _c("img", {
                staticClass: "file",
                attrs: {
                  src: require("@/assets/images/common/file_ppt.png"),
                  alt: "",
                },
              })
            : _vm._e(),
        ]
      ),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar_group" }, [
      _c("img", { staticClass: "avatar", attrs: { src: "", alt: "" } }),
      _c("div", { staticClass: "name" }, [_vm._v("赵老师")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fun_group" }, [
      _c("div", { staticClass: "time" }, [_vm._v("2020-6-8 11:15")]),
      _c("div", { staticClass: "check cp" }, [_vm._v("查看上下文")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }