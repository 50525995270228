<!--
 * @Author       : Hugo
 * @Date         : 2020-05-22 15:07:30
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-11-25 14:39:07
 * @Description  : 发送好友请求
 * @FilePath     : /miaohang/src/components/Contact/SendApplycation.vue
--> 
<template>
  <div class="contact_send_applycation">
    <div class="info_group">
      <div class="name_group">
        <img :src="`${$avatar_url}${result.thumbnail}`" alt="" class="avatar">
        <div class="name">{{result.userName}}</div>
      </div>
      <div class="msg_group">
        <div class="title">告诉他（她）：</div>
        <el-input
          type="textarea"
          placeholder="我是..."
          v-model="textarea"
          class="msg"  
          resize="none"
          maxlength="300"
        >
        </el-input>
        <div class="length_hint">最大字符: {{length}}/300</div>
        <div class="remark_title pb-16 align-self-start">备注名:</div>
        <input type="text" class="remark bbox"
          v-model="friendRemark"
          placeholder="点击为他/她设置备注名"
        >
      </div>
    </div>
    <div class="fun_group">
      <div class="fun cp cancel" @click.stop="$api.common_close_add_friend_box">取消</div>
      <button class="fun cp sure" @click.stop="doApply" v-preventReClick>申请</button>
    </div>
  </div>
</template>

<script>
export default {
  // props:{
  //   result: {
  //     type: Object,
  //     default: null,
  //   }
  // },
  mounted() {
  },
  computed:{
    // 被添加好友数据
    result(){
      return this.$store.state.task_module.add_friend_data;
    },
  },
  watch:{
    // 监听被添加用户信息
    result: function(){},
    textarea: function(val){
      let length = val.length;
      this.length = length;
    },
  },
  data(){
    return{
      length:0,
      textarea:'',
      friendRemark: '',
    }
  },
  methods:{
    /**
     * 取消
     */
    cancelAddFriend(){
      this.$store.commit('close_add_friend_box');
    },
    /**
     * 申请好友
     */
    async doApply(){
      let send_user_id = this.$tools.getCookie('loginUserCode').userId
      let data = {
        rec_user_id: this.result.userId,
        send_user_id,
        send_remark: this.textarea,
        friendRemark: this.friendRemark.trim(),
      }
      let res = await this.$http.request('/projectFriends/friendsApply',data);
      let sendStr = '';
      let icon =  'success';
      switch(res.message){
        case 'sended':
          sendStr = '已发送申请';
          break;
        case 'exits':
          sendStr = '已经是好友';
          break;
        case 'success':
          sendStr = '申请已发出';
          break;
        case 'error':
          sendStr = '请求失败';
          icon = 'none';
          break;
      }
      this.$toast({
        icon,
        title: res.describe,
        desc: '请等待对方反馈',
        // duration: 9000,
      })
      // if(res.status=='sended'|| res.status == 'success' || res.status == 'exits' ){
      // }
      this.cancelAddFriend(); //关闭
      
    }
  }
}
</script>

<style lang="scss" scoped>
.contact_send_applycation{
  @include bbox;
  padding: 50px 50px 40px;
  width:772px;
  // height:368px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius:4px;
  .info_group{
    @include flex;
    width: 100%;
    align-items: stretch;
    .name_group{
      @include flexsb;
      flex-direction: column;
      flex-shrink: 0;
      align-items: flex-start;
      padding-right: 40px;
      border-right: 2px solid $border_color;
      .avatar{
        width:140px;
        height:140px;
        border-radius:4px;
        background-color: #f4f5f6;
      }
      .name{
        padding-top:29px;
        font-size:18px;
        width: 140px;
        font-weight:400;
        color:rgba(51,51,51,1);
      }
    }
    .msg_group{
      @include bbox;
      @include flexsb;
      flex-direction: column;
      width: 100%;
      flex: 1;
      padding-left:30px;
      position: relative;
      .title{
        flex-shrink: 0;
        width: 100%;
        padding-bottom: 16px;
        font-size:16px;
        
        font-weight:400;
        color:rgba(51,51,51,1);
      }
      .msg{
        width:100%;
        // height: 100%;
        height: 300px;
        flex:1;
      }
      .length_hint{
        font-size: 12px;
        color: #999;
        // position: absolute;
        // right:0;
        // bottom: -20px;
        align-self: flex-end;
        line-height: 20px;
      }
      .remark_title{
        font-size: 14px;
        color: #333;
      }
      .remark{
        @include placeholder(#999);
        width: 100%;
        display: block;
        background:rgba(245,248,250,1);
        border:1px solid rgba(221,221,221,1);
        border-radius: 4px;
        font-size: 14px;
        line-height: 30px;
        outline: none;
        padding: 0 15px;
      }
    }
  }
  .fun_group{
    @include flex;
    @include bbox;
    padding-top: 40px;
    justify-content: center;
    .fun{
      margin: 0 20px;
      width:160px;
      line-height:50px;
      background:rgba(0,84,166,0.1);
      border-radius:4px;
      text-align: center;
      font-size:16px;
      border:none;
      font-weight:400;
      color:rgba(0,84,166,1);
    }
    .cancel{}
    .sure{
      color:#fff;
      background-color: $main_blue;
    }
  }
}
</style>
<style lang="css">
  
.contact_send_applycation .msg .el-textarea__inner{
  height: 100%;
  box-sizing: border-box;
  height:100%;
  font-size:16px;
  
  font-weight:400;
  /* color:rgba(204,204,204,1); */
  color: #666;
  width:460px;
  height:80px;
  background:rgba(245,248,250,1);
  border:1px solid rgba(221,221,221,1);
  border-radius:4px;
  font-family: 'PingFang SC', 'MicroSoft YaHei';
}
.contact_send_applycation .msg .el-textarea__inner::-webkit-input-placeholder { /* WebKit browsers */
  color: #999;
}

.contact_send_applycation .msg .el-textarea__inner::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #999;
}

.contact_send_applycation .msg .el-textarea__inner:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #999;
}   
</style>