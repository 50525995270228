<!--
 * @Author       : Hugo
 * @Date         : 2021-06-08 15:53:51
 * @LastEditTime: 2022-05-18 11:27:50
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/PopUp/CommonType/RemoveChatUser.vue
 * @Description  : 移除成员
 * @^_^          : 
-->
<template>
  <div class="remove_chat_user d-flex align-stretch">
    <!-- <div class="d-flex flex-column justify-center align-stretch overflow-hidden" style="height: 100%;"> -->
      <div class="title">选择移除的成员</div>
      <div class="remove_wrapper d-flex">
        <!-- 选择 
          切换时判断是否已选择交接人员为当前被移除人员
        -->
        <div class="select_group d-flex">
          <template
            v-for="(item, index) in dialog_user_list"
          >
            <div class="item d-flex cp flex-shrink-0"
              v-if="item && (item.userId != create_userid)"
              :key="index"
              @click="selectUser(item)"
            >
              <div class="info_group d-flex">
                <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                <div class="username">{{getUserName(item)}}</div>
              </div>
              <div class="select cp"
                :class="{on: item.selected}"
              ></div>
            </div>
          </template>
        </div>
        <!-- 移交 -->
        <div class="turn_over_group d-flex">
          <div class="item d-flex cp flex-shrink-0"
            @click="selectTurnOver(null)"
          >
            <div class="info_group">
              <div class="username">不指定交接人员</div>
            </div>
            <div class="select cp"
              :class="{on:remove_data.turn_over_user === null }"
            ></div>
          </div>
          <template
            v-for="(item,index) in select_list"
          >
            <div class="item flex-shrink-0 d-flex cp"
              v-if="item && item.userId != to_remove_userid"
              :key="index"
              @click="selectTurnOver(item)"
            >
              <div class="info_group d-flex">
                <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                <div class="username">{{getUserName(item)}}</div>
              </div>
              <div class="select cp"
                :class="{on: item.turn_over}"
              ></div>
            </div>
          </template>
          <div class="placeholder" v-if="switch_placeholder"></div>
        </div>
      </div>
      <div class="fun_group d-flex justify-center flex-shrink-0 ">
        <button class="button btn_dark"
          @click="doSure();"
        >确定</button>
        <button class="button btn_light"
          @click="$parent.doClose();"
        >取消</button>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data(){
    return {
      select_list: null,
      remove_data: {
        remove_user: null,
        turn_over_user: null,
      },
      switch_placeholder: false,
    }
  },
  inject:['reload'], 
  computed: {
    /**会话联系人 */
    dialog_user_list(){
      const dialog_user_list = this.$tools.deepClone(this.$store.state.dialog_user_list);
      // dialog_user_list.forEach(item=>{
      //   item.selected = false;
      // })
      return dialog_user_list;
    },

    // 创建者[目前只有创建者能移除组员]
    create_userid(){
      // const chat_detail = this.$store.state;
      // const {chat} = chat_detail;
      // return chat.createUserId;

      // 返回自己id
      return this.$tools.getUserId();

    },

    
    // 待移除id
    to_remove_userid(){
      const {remove_data} = this;
      const {remove_user} = remove_data;
      if(remove_user){
        return remove_user.userId;
      }
      return -1;
    }
    
  },
  watch: {
    dialog_user_list: {
      handler: function(){},
      deep: true,
    },
    select_list: {
      handler: function(){},
      deep: true,
    },
    "remove_data.turn_over_user": function(val){
      if(val === null){
        const {select_list} = this;
        select_list.forEach((item, index)=>{
          if(item.turn_over){
            this.$set(this.select_list[index], 'turn_over', false );
          }
        })
        this.$forceUpdate();
      }
    }
  },
  methods: {
    /**
     * @description: 获取用户名
     * @param {*} item
     * @return {*}
     */    
    getUserName(item){
      return item.friendRemark || item.chatUserName || item.chatUserName || item.userName;
    },
    /**
     * @description: 选择移除的用户
     * @param {*} item
     * @return {*}
     */    
    selectUser(item){
      const {remove_data, switch_placeholder} = this;
      const {turn_over_user} = remove_data;
      if(!switch_placeholder){
        this.switch_placeholder = true;
      }

      // 如果已选择当前要移除的人员为交接人员 则将交接人员置空
      if(turn_over_user && turn_over_user.userId == item.userId){
        remove_data.turn_over_user = null;
        // this.$set(this.remove_data, 'turn_over_user', null);
      }
      this.remove_data.remove_user = item;
      const {select_list} = this;
      

      select_list.forEach( (unit,index) => {
        if(unit && item && unit.userId == item.userId){
          // console.log(index);
          this.$set(this.select_list[index], 'selected', true );
          // this.$set(unit, 'selected', true);
          // console.log(unit, unit.selected);
        }else{
          if(unit.selected){
            // console.log(index);
            this.$set(this.select_list[index], 'selected', false );
          }
        }
      })
      this.$forceUpdate();
      // console.log(select_list);
    },
    selectTurnOver(item){
      this.remove_data.turn_over_user = item;
      const {select_list} = this;
     
        select_list.forEach( (unit,index) => {
          if(item === null){
            if(unit.turn_over){
              this.$set(this.select_list[index], 'turn_over', false );
            }
          }else{
            if(unit && item && unit.userId == item.userId){
              // console.log(index);
              this.$set(this.select_list[index], 'turn_over', true );
              // this.$set(unit, 'turn_over', true);
              // console.log(unit, unit.turn_over);
            }else{
              if(unit.turn_over){
                // console.log(index);
                this.$set(this.select_list[index], 'turn_over', false );
              }
            }
          }
        })
    
      
      this.$forceUpdate();
    },
    doSure(){
      const {remove_data} = this;
      const {remove_user, turn_over_user} = remove_data;
      const has_turn_over = Boolean(turn_over_user !== null);
      const data = {
        chatId: this.$global.decodeBase64(this.$route.params.id),
        userId: remove_user.userId,
        toUserId: turn_over_user ? turn_over_user.userId : '',
      }
      if(remove_user !== null){
        this.$openConfirm({
          title: `是否移除成员: '${this.getUserName(remove_user)}'`,
          desc:`${has_turn_over ? '交接人员为: '+ this.getUserName(turn_over_user) : '不指定交接人员'}`,
          sure:'',
          cancel:'',
          onSure:async ()=>{
            this.$global.preventDuplicateClicks(
              async () => {
                let res = await this.$global.doPost({
                  url: '/project/removeUser',
                  data,
                  notice: '移除失败',
                  loading: true,
                  loadingText: '移除中'
                })
                if(res.message === 'success'){
                  this.$parent.doClose();
                  this.reload();
                }
              }
            )
          },
          onCancel:()=>{},
        })

      }else{
        this.$parent.doClose();
      }
    },
    doClose(){
      this.doClear();
    },
    doClear(){
      this.$set(this, 'remove_data', {
        remove_user: null,
        turn_over_user: null,
      })
      this.switch_placeholder = false;
    },
  },
  mounted(){
    // console.log(this.dialog_user_list);
    if(this.dialog_user_list){
      this.select_list = this.dialog_user_list;
    }
  }
}
</script>
<style lang="scss" scoped>
.remove_chat_user{
  @include bbox;
  // @include flex;
  flex-direction: column;
  background-color: #fff;
  // padding: 20px 0;
  border-radius: 10px;
  height: 100%;
  // width: 100%;
  max-height: 800px;
  .title{
    @include bbox;
    font-size: 18px;
    color: #333;
    text-align: center;
    padding: 20px 0 20px;
    border-bottom: 2px solid $border_color;
    flex-shrink: 0;
  }
  .remove_wrapper{
    align-items: stretch;
    flex: 1;
    height: 0;
  }
  .select_group, .turn_over_group{
    padding: {
      top: 10px;
      bottom: 10px;
    }
    width: 400px;
  }
  .select_group{
    @include scrollbar;
    @include bbox;
    overflow-y: auto;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 20px;
    align-items: stretch;
    // flex-basis: 400px;
    flex: 1;
    flex-grow: 1;
    // flex-shrink: 0;
    border-right: 2px solid $border_color;
  }
  .turn_over_group{
    @include scrollbar;
    @include bbox;
    overflow-y: auto;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 20px;
    align-items: stretch;
    // flex-basis: 400px;
    flex:1;
    // flex-shrink: 0;
    flex-grow: 1;
  }
  .item{
    justify-content: space-between;
    padding: 5px 0;
    overflow:hidden;
  }
  .avatar{
    width: 44px;
    height: 44px;
    border-radius: 4px;
    padding-right: 10px;
    flex-shrink: 0
  }
  .username{
    @include single_line_intercept;
    line-height: 44px;
    white-space: nowrap;
    flex: 1;
    width: 100%;
  }
  .info_group{
    padding-right: 10px;
    flex:1 ;
    width: 100%;
    overflow: hidden;
  }
  .select{
    @include bbox;
    flex-shrink: 0;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 2px solid #999;
    &.on{
      border: none;
      background-image:url(~@/assets/images/common/selected.png);
    }
  }
  .fun_group{
    padding: 20px 0;
    border-top: 2px solid $border_color;
  }
  .button{
    font-size: 16px;
    text-align: center;
    padding: 0 45px;
    line-height: 40px;
    border-radius: 4px;
    margin: 0 20px;
  }
  .placeholder{
    height: 54px;
  }
}
</style>