var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "create_dialog_select_unit cp",
      class: { on: _vm.on },
      on: { click: _vm.doSelect },
    },
    [
      _c("div", { staticClass: "name_group" }, [
        _c("img", {
          staticClass: "avatar",
          attrs: {
            src: `${_vm.$avatar_url}${_vm.userData.thumbnail}@!small200`,
            alt: "",
          },
        }),
        _c("div", {
          staticClass: "name",
          domProps: {
            innerHTML: _vm._s(
              _vm.searchName ? _vm.searchName : _vm.userData.friendRemark
            ),
          },
        }),
      ]),
      _vm.userData && (!_vm.userData.isInChat || _vm.is_single_dialog)
        ? _c("div", { staticClass: "select_box" }, [
            _c("div", { staticClass: "select cp" }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }