var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v_login_addition_information bbox_wrapper",
    },
    [
      _c("div", { staticClass: "title_group" }, [_vm._v("\n    实名认证\n  ")]),
      _c(
        "div",
        {
          staticClass: "information_wrapper d-flex align-center justify-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "avatar_group d-flex align-center flex-column justify-center",
            },
            [
              _c("div", {
                staticClass: "avatar",
                style: {
                  backgroundImage: `url(${_vm.$avatar_url}${_vm.appUserInfo.thumbnail}@!small200)`,
                },
              }),
              _c("div", { staticClass: "username" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.appUserInfo.userName) + "\n      "
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "info_wrapper" }, [
            _c("div", { staticClass: "info_group" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "value" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass:
                        "select_group views_active_page_laboratory_index_select_group",
                      attrs: {
                        clearable: "",
                        placeholder: "请选择身份",
                        value: "identities",
                      },
                      model: {
                        value: _vm.identity,
                        callback: function ($$v) {
                          _vm.identity = $$v
                        },
                        expression: "identity",
                      },
                    },
                    _vm._l(_vm.identityList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "info_group" }, [
              _vm._m(1),
              _c("div", { staticClass: "value" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userRealName,
                      expression: "userRealName",
                    },
                  ],
                  staticClass: "text",
                  attrs: { type: "text", placeholder: "请输入真实姓名" },
                  domProps: { value: _vm.userRealName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.userRealName = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "info_group" }, [
              _vm._m(2),
              _c("div", { staticClass: "value" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userCardNo,
                      expression: "userCardNo",
                    },
                  ],
                  staticClass: "text",
                  attrs: {
                    type: "text",
                    placeholder: "请输入身份证号码",
                    maxlength: "18",
                  },
                  domProps: { value: _vm.userCardNo },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.userCardNo = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "info_group" }, [
              _vm._m(3),
              _c("div", { staticClass: "value" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ownedEnterprises,
                      expression: "ownedEnterprises",
                    },
                  ],
                  staticClass: "text",
                  attrs: { type: "text", placeholder: "请输入单位名称" },
                  domProps: { value: _vm.ownedEnterprises },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.ownedEnterprises = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMajor,
                    expression: "showMajor",
                  },
                ],
                staticClass: "info_group",
              },
              [
                _vm._m(4),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass:
                          "select_group views_active_page_laboratory_index_select_group",
                        attrs: {
                          clearable: "",
                          placeholder: "请选择专业",
                          value: "identities",
                        },
                        model: {
                          value: _vm.major,
                          callback: function ($$v) {
                            _vm.major = $$v
                          },
                          expression: "major",
                        },
                      },
                      _vm._l(_vm.majorList, function (item) {
                        return _c("el-option", {
                          key: item.name,
                          attrs: { label: item.name, value: item.name },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showMajor,
                    expression: "!showMajor",
                  },
                ],
                staticClass: "info_group",
              },
              [
                _vm._m(5),
                _c("div", { staticClass: "value" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.department,
                        expression: "department",
                      },
                    ],
                    staticClass: "text",
                    attrs: { type: "text", placeholder: "请输入部门名称" },
                    domProps: { value: _vm.department },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.department = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "fun_group d-flex justify-center" }, [
        _c(
          "div",
          {
            staticClass: "btn btn_dark cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doSubmit.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n      完成\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key" }, [
      _c("div", { staticClass: "symbol" }, [
        _vm._v("\n            *\n          "),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n            身  份:\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key" }, [
      _c("div", { staticClass: "symbol" }, [
        _vm._v("\n            *\n          "),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n            真实姓名:\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key" }, [
      _c("div", { staticClass: "symbol" }, [
        _vm._v("\n            *\n          "),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n            身份证号码:\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key" }, [
      _c("div", { staticClass: "symbol" }, [
        _vm._v("\n            *\n          "),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n            单位名称:\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key" }, [
      _c("div", { staticClass: "symbol" }, [
        _vm._v("\n            *\n          "),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n            专  业:\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key" }, [
      _c("div", { staticClass: "symbol" }),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n            部  门:\n          "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }