var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teaching_package_page d-flex flex-column" },
    [
      _c(
        "div",
        {
          staticClass:
            "sidebar_group bbox d-flex justify-space-between align-center",
        },
        [
          [_c("div", { staticClass: "from" })],
          _c(
            "div",
            { staticClass: "d-flex flex-align" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-end",
                    trigger: "click",
                    "visible-arrow": false,
                    "popper-class": "teaching_package_page_menu_poppver",
                  },
                  model: {
                    value: _vm.switch_menu,
                    callback: function ($$v) {
                      _vm.switch_menu = $$v
                    },
                    expression: "switch_menu",
                  },
                },
                [
                  _c("div", { staticClass: "menu_list" }, [
                    _c(
                      "div",
                      {
                        staticClass: "item cp",
                        on: {
                          click: function ($event) {
                            return _vm.doShare()
                          },
                        },
                      },
                      [_vm._v("分享")]
                    ),
                  ]),
                  _c("template", { slot: "reference" }, [
                    _c("div", {
                      staticClass: "menu cp",
                      on: {
                        click: function ($event) {
                          return _vm.handleMenuClick()
                        },
                      },
                    }),
                  ]),
                ],
                2
              ),
              _c("div", {
                staticClass: "close_group cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.doClose()
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("div", { staticClass: "container" }, [
        _vm.is_buy
          ? _c("div", { staticClass: "buy_group" }, [
              _c("img", {
                staticClass: "is_buy",
                attrs: {
                  src: require("@/assets/images/package/is_buy.png"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.package_data
          ? _c(
              "div",
              {
                staticClass:
                  "header_group bbox d-flex align-center justify-space-between",
              },
              [
                _c("div", { staticClass: "info_wrapper" }, [
                  _c("div", { staticClass: "title_group" }, [
                    _vm._v(_vm._s(_vm.package_data.title)),
                  ]),
                  _vm.package_data.webpic
                    ? _c("div", { staticClass: "picture_group" }, [
                        _c("img", {
                          staticClass: "picture",
                          attrs: {
                            src: `${_vm.$img_url}${_vm.package_data.webpic}`,
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "info_group" }, [
                    _c("div", { staticClass: "descript_group" }, [
                      _c(
                        "div",
                        {
                          staticClass: "descript",
                          domProps: {
                            innerHTML: _vm._s(_vm.package_data.content),
                          },
                        },
                        [_vm._v("简介内容")]
                      ),
                    ]),
                  ]),
                ]),
                !_vm.is_buy
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "purchase_group bbox d-flex flex-column justify-center",
                      },
                      [
                        _vm.price_status
                          ? _c("div", { staticClass: "title" }, [
                              _vm._v("普通用户购买"),
                            ])
                          : _vm._e(),
                        _vm.price_status
                          ? _c(
                              "div",
                              { staticClass: "price_group d-flex align-end" },
                              [
                                _c("div", { staticClass: "symbol" }, [
                                  _vm._v("¥ "),
                                ]),
                                _c("div", { staticClass: "price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.package_data.price
                                        ? _vm.package_data.price / 100
                                        : 1000
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.price_status && 0
                          ? _c("div", { staticClass: "hint_group" }, [
                              _vm._v("成为"),
                              _c("span", { staticClass: "mark" }, [
                                _vm._v("VIP"),
                              ]),
                              _vm._v("用户,立减"),
                              _c("span", { staticClass: "mark" }, [
                                _vm._v("30"),
                              ]),
                              _vm._v("元"),
                            ])
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.status_free_btn,
                                expression: "status_free_btn",
                              },
                            ],
                            staticClass: "btn_dark button free",
                            on: {
                              click: function ($event) {
                                return _vm.doBuyForFree()
                              },
                            },
                          },
                          [_vm._v("免费领取")]
                        ),
                        _vm.buy_status
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "fun_group d-flex align-center un_sel",
                              },
                              [
                                0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "button btn_dark buy_now",
                                      },
                                      [_vm._v("立即购买")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button consulting bbox d-flex align-center justify-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.touchConsulting()
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: require("@/assets/images/package/consult.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v("咨询"),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.maker_status
                          ? _c("div", { staticClass: "maker" }, [
                              _vm._v("您是制作者"),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "task_list_group" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("教学模版列表（" + _vm._s(_vm.task_list.length) + "）："),
          ]),
          _c(
            "div",
            { staticClass: "list_group" },
            [
              _vm._l(_vm.task_list, function (item, index) {
                return [
                  _c("thumb-task", {
                    key: index,
                    staticClass: "unit cp un_sel",
                    attrs: {
                      item: item,
                      menu: false,
                      show_time: false,
                      show_tag: false,
                      separate: false,
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.openTask(item)
                      },
                    },
                  }),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm.switch_consulting
        ? _c("consulting", {
            attrs: { param_id: Number(_vm.package_id) },
            on: { handleClose: _vm.closeConsulting },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }