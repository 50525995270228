var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teaching_task bbox_wrapper" }, [
    _c("div", {
      staticClass: "close cp",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.touchDelect.apply(null, arguments)
        },
      },
    }),
    _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n      " + _vm._s(_vm.taskInfo.name) + "\n    "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "info_group d-flex align-center justify-space-between" },
      [
        _c("div", { staticClass: "name_group" }, [
          _c("img", {
            staticClass: "avatar",
            attrs: {
              src: `${_vm.$avatar_url}${
                _vm.taskInfo.createuserthumbnail ||
                _vm.taskInfo.updateuserthumbnail
              }@!small200`,
              alt: "",
            },
          }),
        ]),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tag_group" }, [
      _c("div", { staticClass: "tag teach" }, [_vm._v("\n        教\n      ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }