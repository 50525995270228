var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "communication_record_container" }, [
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "template" },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords(
                          "按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述",
                          196
                        )
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "msg" },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords(
                          "按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述",
                          196
                        )
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "file" },
          scopedSlots: _vm._u([
            {
              key: "filename",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords("ppt1966描述", 196)
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "template" },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords(
                          "按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述",
                          196
                        )
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "msg" },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords(
                          "按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述",
                          196
                        )
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "file" },
          scopedSlots: _vm._u([
            {
              key: "filename",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords("ppt1966描述", 196)
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "template" },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords(
                          "按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述",
                          196
                        )
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "msg" },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords(
                          "按照VIVO的KV，开始延展设计，包括手册、易拉宝、胸卡、T恤，详细情况见下面1966描述",
                          196
                        )
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unit" },
      [
        _c("CommunicationRecordMsgUnit", {
          attrs: { type: "file" },
          scopedSlots: _vm._u([
            {
              key: "filename",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$tools.highlightKeywords("ppt1966描述", 196)
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }